import React, { CSSProperties, FunctionComponent } from 'react'
import { useMindshareSelector } from 'mindshare.customhooks'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanTemplateFields, fieldsInPlanByLevel, availableFieldsByLevel } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { RootState } from '../../redux/reducers'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FlightTableFieldsContainer from './FlightTableFieldsContainer'
import FlightGroupFlightButtonContainer from './FlightGroupFlightButtonContainer'
import { flightStartDate, flightEndDate } from 'Components/MediaPlanVersion/constants/entities/FlightDates'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  flightGroupIndex: number
  calculationMode: boolean
  collapse: boolean
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (selected: string) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  clickableFieldProps: IClickableFieldProps
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightsTableContainer: FunctionComponent<IProps> = ({
  calculationMode,
  collapse,
  flightGroupIndex,
  tokenHandler,
  unfilteredHierarchies,
  masteredListsData,
  onAvailableFieldSelected,
  getStickyStyle,
  linkedLookupFieldsHelperValues,
  clickableFieldProps,
  masteredDataHelperValues,
  financeListFieldsData
}: IProps) => {
  const flightFields = useMindshareSelector((state: RootState) =>
    fieldsInPlanByLevel(
      state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields,
      FieldLevelType.FLIGHT
    )
  ) as IMediaPlanTemplateFields[]

  const availableFlightFields = useMindshareSelector((state: RootState) =>
    availableFieldsByLevel(
      state.mediaPlans.currentMediaPlanVersion.mediaPlanVersionFields,
      FieldLevelType.FLIGHT
    )
  ) as IMediaPlanTemplateFields[]

  const sortedFlightFields = flightFields && [...flightFields, flightStartDate, flightEndDate].sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <>
      <FlightGroupFlightButtonContainer
        {...{ flightFields, flightGroupIndex, getStickyStyle }}
      />
      <FlightTableFieldsContainer
        availableMediaPlanFields={availableFlightFields}
        calculationMode={calculationMode}
        collapse={collapse}
        masteredListsData={masteredListsData}
        flightFields={sortedFlightFields}
        onAvailableFieldSelected={onAvailableFieldSelected}
        tokenHandler={tokenHandler}
        unfilteredHierarchies={unfilteredHierarchies}
        availableFlightFields={availableFlightFields}
        flightGroupIndex={flightGroupIndex}
        linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
        clickableFieldProps={clickableFieldProps}
        masteredDataHelperValues={masteredDataHelperValues}
        financeListFieldsData={financeListFieldsData}
      />
    </>
  )
}

export default FlightsTableContainer
