import React from 'react'
import { Card } from 'antd'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { TemplateFieldType } from 'Apis/generated/templateFieldTypesApi'
import { TemplateFieldAvailability } from 'Apis/generated/templateFieldAvailabilitiesApi'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { SortableTemplatePlanFieldWrapper } from './SortableTemplatePlanFieldItem'
import { ISelectedHierarchies } from 'Components/TemplateFieldSelection/constants/entities/ISelectedHierarchies'
import { IRowFunctions } from 'Components/TemplateFieldSelection/hooks/useTemplateFieldRows'
import { ILinkedLookupFieldsHelperValues } from 'Components/LinkedLookup/constants/entities/ILinkedLookupHelperValues'
import { ITemplateMasteredFieldsHelperValues } from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useFilteredHierarchies } from 'Hooks/CustomHooks'
import { IValidationResult } from './constants/entities/IValidationResult'

interface IProps {
  availableMetaData: IClientMediaPlanField[]
  hierarchies: IMSHierarchies
  selectedParentHierarchiesHelperValues: ISelectedHierarchies
  selectedHierarchies: ISelectedHierarchies
  isAliased: boolean
  itemList: IMediaPlanFieldRow[]
  masteredListsData: IMasteredListsData
  rowFunctions: IRowFunctions
  templateAvailability: TemplateFieldAvailability[]
  templateFieldType: TemplateFieldType[]
  tabLevel: FieldLevelType
  linkedLookupFieldsHelperValues: ILinkedLookupFieldsHelperValues
  masteredDataHelperValues: ITemplateMasteredFieldsHelperValues
  errors: IValidationResult[]
}

const TemplateFieldSelectionComponent: React.FunctionComponent<IProps> = ({ itemList, ...props }: IProps): React.ReactElement => {
  const tabColumnClass = props.tabLevel === FieldLevelType.PLAN ? 'template-plan-tab-columns' : 'template-flight-tab-columns'
  const classNames = `table-column-titles template-plan-columns ${tabColumnClass}`
  const { selectedParentHierarchiesHelperValues, selectedHierarchies } = props

  const { filteredHierarchies } = useFilteredHierarchies(
    selectedParentHierarchiesHelperValues?.brand || selectedHierarchies.brand,
    selectedParentHierarchiesHelperValues?.business || selectedHierarchies.business,
    selectedParentHierarchiesHelperValues?.geography || selectedHierarchies.geography,
    selectedParentHierarchiesHelperValues?.media || selectedHierarchies.media
  )

  return (
    <Card>
      <div className={classNames}>
        <div>Field Name</div>
        <div>{props.isAliased && 'Alias'}</div>
        <div>Field Type</div>
        <div>Set Value</div>
        <div>Availability</div>
        <div>Editable</div>
        {props.tabLevel === FieldLevelType.PLAN && <div>Footer</div>}
        <div>Options</div>
        <div />
      </div>
      <SortableTemplatePlanFieldWrapper
        onSortEnd={props.rowFunctions.onSortEnd}
        itemList={itemList}
        itemProps={{ ...props, hierarchies: filteredHierarchies, unfilteredHierarchies: props.hierarchies }}
      />
    </Card>
  )
}

export default TemplateFieldSelectionComponent
