import React, { CSSProperties, FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react'
import { IMSHierarchies, formatUpperCaseFirstLetter } from 'mindshare.layout'
import { useMindshareSelector } from 'mindshare.customhooks'
import { RootState } from 'Reducers/index'
import { IMediaPlanTemplateFields, getFieldColumnName, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import FlightGroupFieldContainer from 'Containers/MediaPlanVersion/FlightGroupFieldContainer'
import FlightGroupRow from 'Containers/MediaPlanVersion/FlightGroupRow'
import FlightsTableContainer from 'Containers/MediaPlanVersion/FlightsTableContainer'
import FlightsGridLabelsContainer from 'Containers/MediaPlanVersion/FlightsGridLabelsContainer'
import FlightsGridSectionContainer from 'Containers/MediaPlanVersion/FlightsGridSectionContainer'
import ButtonFlightGroupComponent from 'Components/MediaPlanVersion/ButtonFlightGroupComponent'
import { useFlightGroupCommands } from 'Hooks/useFlightGroupCommands'
import { useOutsideClick } from 'Hooks/useOutsideClick'
import { useAppDispatch, useAppSelector } from '../../store'
import { copyFlight, setSelectedDates, setSelectedFlights } from 'Actions/mediaPlansActions'
import {
  selectCalendarView,
  selectCollapsedFlightGroups,
  selectFlightGroups as getFlightGroups,
  selectMediaPlanErrors,
  selectCopiedFlight,
  selectSelectedFlights,
  selectFlightGroupSelection
} from '../../selectors'
import type { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { usePlanLinkedLookups } from 'Components/MediaPlanVersion/hooks/usePlanLinkedLookups'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { SubtotalsFlightGroupFieldContainer } from 'Containers/MediaPlanVersion/SubtotalsFlightGroupFieldContainer'
import { KeyCode } from 'Constants/enums/KeyCode'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  calculationMode: boolean
  filteredFlightGroups: IFlightGroup[]
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  planHierarchies: IMSHierarchies
  mediaPlanFields: IMediaPlanTemplateFields[]
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  unfilteredHierarchies: IMSHierarchies
  displayFlightDates: boolean
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  collapsedViewField: string
  compressedCalendarView: boolean
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
  availableFlightFields?: IMediaPlanMetaField[]
  originalFlightGroups: IFlightGroup[]
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightGroupContainer: FunctionComponent<IProps> = ({
  calculationMode,
  filteredFlightGroups,
  masteredListsData,
  mediaPlanFields,
  availableMediaPlanFields,
  tokenHandler,
  onAvailableFieldSelected,
  handleAddField,
  removePlanField,
  getStickyStyle,
  unfilteredHierarchies,
  displayFlightDates,
  expandedHierarchiesFlightGroupsValues,
  collapsedViewField,
  compressedCalendarView,
  masteredDataHelperValues,
  planHierarchies,
  clickableFieldProps,
  availableFlightFields,
  originalFlightGroups,
  isLoadingHierarchies,
  financeListFieldsData
}: IProps) => {
  const dispatch = useAppDispatch()
  const sortedFlightGroups = useMemo(
    () => [...originalFlightGroups].sort((current, next) => current.sortOrder - next.sortOrder),
    [originalFlightGroups]
  )
  const errors = useAppSelector(selectMediaPlanErrors)
  const linkedLookupFieldsHelperValues = usePlanLinkedLookups()

  const getFieldError = useCallback(
    (clientMediaPlanFieldId, flightGroup) => {
      const flights = flightGroup?.flights?.filter((flight) => flight.merge)

      const currentError = errors.find(e => {
        const flightWithError = flights?.find(flight => flight.mediaPlanFlightId === e.instanceId)

        return flightWithError && e.fieldId === clientMediaPlanFieldId
      })

      return currentError?.error ?? ''
    },
    [errors]
  )

  const flightGroupSelection = useAppSelector(selectFlightGroupSelection)
  const {
    canInsert: isAnyFlightGroupCopied,
    canInsertFlightGroup,
    selectFlightGroups,
    clearSelection,
    copyFlightGroups,
    duplicateFlightGroup,
    upsertFlightGroups,
    insertFlightGroups,
    toggleFlightGroup,
    deleteFlightGroup,
    resetFlightGroups,
    checkIsCopied,
    checkCanUpsert,
    checkCanCopySelection,
    clearClipboard
  } = useFlightGroupCommands({
    flightGroups: sortedFlightGroups,
    flightGroupFields: mediaPlanFields,
    masteredListsData
  })
  const calendarView = useAppSelector(selectCalendarView)
  const originalFlightGroupData = useAppSelector(getFlightGroups)
  const collapsedFlightGroups = useAppSelector(selectCollapsedFlightGroups)
  const selectedFlights = useAppSelector(selectSelectedFlights)
  const copiedFlight = useAppSelector(selectCopiedFlight)
  const collapsedRows = useMindshareSelector((state: RootState) => state.mediaPlans.collapsedFlightGroupRows) as number[]
  const clearFlightAndFlightGroupSelections = useCallback((event?: MouseEvent) => {
    const withinDropdownOrModal = (event?.target as HTMLElement)?.closest('.ant-dropdown') ||
      (event?.target as HTMLElement)?.closest('.ant-picker-dropdown') ||
      (event?.target as HTMLElement)?.closest('.ant-modal-root') ||
      (event?.target as HTMLElement)?.closest('.ant-tooltip') ||
      ((event?.target as HTMLElement)?.className &&
      typeof (event?.target as HTMLElement).className.includes !== 'undefined' &&
      (event?.target as HTMLElement).className.includes('ant-scrolling-effect'))

    const withinMergeModal =
      (event?.target as HTMLElement)?.closest('.merge')

    if (withinDropdownOrModal || withinMergeModal) {
      return
    }

    clearSelection()
    dispatch(setSelectedFlights([]))
    dispatch(setSelectedDates([]))
    dispatch(copyFlight(null))
  }, [clearSelection, dispatch])
  const outsideClickRef = useOutsideClick<HTMLTableSectionElement>(clearFlightAndFlightGroupSelections)

  useEffect(() => {
    if (selectedFlights?.length) {
      clearSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlights])

  useEffect(() => {
    if (copiedFlight?.flightGroupIndex !== undefined) {
      clearClipboard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedFlight])

  const onSelect = useCallback((event, point, fgSelection, isSelected) => {
    selectFlightGroups(event, point, fgSelection, isSelected)
  }, [selectFlightGroups])


  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTableSectionElement>) => {
    const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.keyCode].filter(Boolean).join('+')
    if (handlerKey !== `ctrl+${KeyCode.C}`) {
      return
    }

    if (!checkCanCopySelection(flightGroupSelection)) {
      return
    }

    copyFlightGroups(flightGroupSelection)
  }, [
    checkCanCopySelection,
    flightGroupSelection,
    copyFlightGroups
  ])

  return (
    <tbody ref={outsideClickRef} onKeyDown={handleKeyDown}>
      {filteredFlightGroups.map((flightGroup, index) => {
        const originalIndex = originalFlightGroupData.findIndex(ofg => ofg.mediaPlanFlightGroupId === flightGroup.mediaPlanFlightGroupId)
        const flightGroupIndex = originalIndex === -1 && !flightGroup.subtotal ? index : originalIndex
        const isCollapsed = collapsedRows.includes(flightGroup.mediaPlanFlightGroupId)
        const isSubtotalGroup = !!flightGroup.subtotal
        const key = isSubtotalGroup ? `${flightGroup.mediaPlanFlightGroupId}-${index}` : flightGroup.mediaPlanFlightGroupId

        return (
          <FlightGroupRow
            key={key}
            flightGroupId={flightGroup.mediaPlanFlightGroupId}
            flightGroupIndex={flightGroupIndex}
            isAnyFlightGroupCopied={isAnyFlightGroupCopied}
            isCopied={checkIsCopied(flightGroup)}
            isCollapsed={isCollapsed && calendarView !== 'table'}
            canCopyFlightGroup={checkCanCopySelection}
            onInsertFlightGroups={insertFlightGroups}
            onClearSelection={clearSelection}
            isSubtotalGroup={isSubtotalGroup}
          >
            <ButtonFlightGroupComponent
              calendarView={calendarView}
              collapse={isCollapsed}
              flightGroupArrayLength={filteredFlightGroups.length}
              flightGroupId={flightGroup.mediaPlanFlightGroupId}
              flightGroupIndex={flightGroupIndex}
              canInsert={canInsertFlightGroup}
              getStickyStyle={getStickyStyle}
              onCollapse={toggleFlightGroup}
              onDeleteFlightGroup={deleteFlightGroup}
              onDuplicateFlightGroup={duplicateFlightGroup}
              onSelect={onSelect}
              onReset={resetFlightGroups}
              onCopy={copyFlightGroups}
              onInsert={insertFlightGroups}
              isSubtotalGroup={isSubtotalGroup}
            />
            {!isSubtotalGroup ? (
              mediaPlanFields.map((flightGroupField, flightGroupFieldIndex) => (
                <FlightGroupFieldContainer
                  availableMediaPlanFields={availableMediaPlanFields}
                  calculationMode={calculationMode}
                  collapse={isCollapsed}
                  collapsedFlightGroups={collapsedFlightGroups}
                  flightGroupIndex={flightGroupIndex}
                  flightGroupFieldIndex={flightGroupFieldIndex}
                  key={flightGroupField.mediaPlanVersionFieldId}
                  masteredListsData={masteredListsData}
                  mediaPlanField={flightGroupField}
                  tokenHandler={tokenHandler}
                  getStickyStyle={getStickyStyle}
                  onAvailableFieldSelected={onAvailableFieldSelected}
                  onSelectFlightGroups={onSelect}
                  onResetFlightGroups={resetFlightGroups}
                  onCopyFlightGroups={copyFlightGroups}
                  onUpsertFlightGroups={upsertFlightGroups}
                  isCopied={checkIsCopied(flightGroup, flightGroupField)}
                  isNewlyAdded={flightGroup.mediaPlanFlightGroupId < 0}
                  canUpsert={checkCanUpsert({ flightGroupFieldIndex, flightGroupIndex })}
                  canCopy={checkCanCopySelection}
                  onClearSelection={clearSelection}
                  onClearClipboard={clearClipboard}
                  linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                  unfilteredHierarchies={unfilteredHierarchies}
                  expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
                  masteredDataHelperValues={masteredDataHelperValues}
                  flightGroupId={flightGroup.mediaPlanFlightGroupId}
                  clickableFieldProps={clickableFieldProps}
                  originalIndex={index}
                  isLoadingHierarchies={isLoadingHierarchies}
                  financeListFieldsData={financeListFieldsData}
                />
              ))) : (
              mediaPlanFields.map((flightGroupField) => {
                const columnName = getFieldColumnName(flightGroupField)
                const fieldId = flightGroupField.mediaPlanTemplateFieldId || flightGroupField.mediaPlanVersionFieldId
                const collapsedFlightGroup = collapsedFlightGroups.includes(fieldId)

                return (
                  <SubtotalsFlightGroupFieldContainer
                    key={flightGroupField.mediaPlanVersionFieldId}
                    flightGroupField={flightGroupField}
                    fieldValue={flightGroup[`subtotal${formatUpperCaseFirstLetter(columnName)}`]}
                    fieldId={fieldId}
                    collapsedFlightGroup={collapsedFlightGroup}
                    getStickyStyle={getStickyStyle}
                    flightGroupIndex={flightGroupIndex}
                    expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
                    hierarchies={planHierarchies}
                  />
                )
              })
            )}
            {
              calendarView !== 'table'
                ? (
                  <>
                    <FlightsGridLabelsContainer
                      flightGroup={flightGroup}
                      calculationMode={calculationMode}
                      handleAddField={handleAddField}
                      flightGroupIndex={flightGroupIndex}
                      onAvailableFieldSelected={onAvailableFieldSelected}
                      removePlanField={removePlanField}
                      getStickyStyle={getStickyStyle}
                      getFieldError={getFieldError}
                      isCollapsed={isCollapsed && calendarView !== 'table'}
                      collapsedViewField={collapsedViewField}
                      clickableFieldProps={clickableFieldProps}
                      isSubtotalGroup={isSubtotalGroup}
                      availableFlightFields={availableFlightFields}
                      displayFlightDates={displayFlightDates}
                    />
                    <FlightsGridSectionContainer
                      calculationMode={calculationMode}
                      handleAddField={handleAddField}
                      flightGroupId={flightGroup.mediaPlanFlightGroupId}
                      flightGroupIndex={flightGroupIndex}
                      masteredListsData={masteredListsData}
                      onAvailableFieldSelected={onAvailableFieldSelected}
                      removePlanField={removePlanField}
                      tokenHandler={tokenHandler}
                      calendarView={calendarView}
                      unfilteredHierarchies={unfilteredHierarchies}
                      linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                      isCollapsed={isCollapsed && calendarView !== 'table'}
                      collapsedViewField={collapsedViewField}
                      compressedCalendarView={compressedCalendarView}
                      clickableFieldProps={clickableFieldProps}
                      masteredDataHelperValues={masteredDataHelperValues}
                      isSubtotalGroup={isSubtotalGroup}
                      flightGroup={flightGroup}
                      availableFlightFields={availableFlightFields}
                      displayFlightDates={displayFlightDates}
                      financeListFieldsData={financeListFieldsData}
                    />
                  </>
                )
                : (
                  <FlightsTableContainer
                    calculationMode={calculationMode}
                    collapse={isCollapsed}
                    tokenHandler={tokenHandler}
                    unfilteredHierarchies={unfilteredHierarchies}
                    masteredListsData={masteredListsData}
                    onAvailableFieldSelected={onAvailableFieldSelected}
                    flightGroupIndex={flightGroupIndex}
                    getStickyStyle={getStickyStyle}
                    linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                    clickableFieldProps={clickableFieldProps}
                    masteredDataHelperValues={masteredDataHelperValues}
                    financeListFieldsData={financeListFieldsData}
                  />
                )
            }
          </FlightGroupRow>
        )
      })}
    </tbody>
  )
}

export default memo(FlightGroupContainer)
