import React, { FunctionComponent } from 'react'
import { IMSHierarchies } from 'mindshare.layout'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import FieldAvailableComponent from '../../components/FieldAvailableComponent'
import FieldContainer from 'Containers/MediaPlanVersion/FieldTypeSwitchContainer'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IMediaPlanVersionMasteredFieldsHelperValues, IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IProps {
  valid: boolean
  index: number
  startDate: string
  endDate: string
  flightGroupIndex: number
  flightIndex: number
  calculationMode: boolean
  mediaPlanField: IMediaPlanTemplateFields
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (selected: string) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightTableFieldContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    valid,
    startDate,
    index,
    endDate,
    flightIndex,
    calculationMode,
    mediaPlanField,
    flightGroupIndex,
    masteredListsData,
    unfilteredHierarchies,
    tokenHandler,
    availableMediaPlanFields,
    onAvailableFieldSelected,
    linkedLookupFieldsHelperValues,
    masteredDataHelperValues,
    financeListFieldsData
  } = props

  if (mediaPlanField.mediaPlanVersionFieldId !== 0) {
    return (
      <FieldContainer
        calculationMode={calculationMode}
        field={mediaPlanField}
        masteredListsData={masteredListsData}
        unfilteredHierarchies={unfilteredHierarchies}
        tokenHandler={tokenHandler}
        valid={valid}
        flightGroupIndex={flightGroupIndex}
        flightIndex={flightIndex}
        startDate={startDate}
        endDate={endDate}
        merge={true}
        defaultClass='input-flight-group-value'
        linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
        masteredDataHelperValues={masteredDataHelperValues}
        financeListFieldsData={financeListFieldsData}
      />
    )
  } else if (flightGroupIndex === 0 && index === 0) {
    return (
      <FieldAvailableComponent
        onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue)}
        availableMediaPlanFields={availableMediaPlanFields}
      />
    )
  } else {
    return null
  }
}

export default FlightTableFieldContainer
