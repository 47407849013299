import { Action, Dispatch } from 'redux'
import { batch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import { message, Modal } from 'antd'
import { uniqBy, omit } from 'lodash'
import moment from 'moment'
import { differenceInCalendarDays } from 'date-fns'
import { formatLowerCaseFirstLetter, isNumeral, IMSHierarchies } from 'mindshare.layout'

import * as mediaPlanService from 'Services/mediaPlanService'
import { createMediaPlan } from './templatesActions'
import { RootState } from 'Reducers/index'
import { initialState } from 'Reducers/mediaPlansReducer'
import { clearCurrentMediaPlanTemplate } from 'Actions/mediaPlanTemplatesActions'
import {
  dateFormat,
  flightWidthCalculation,
  getWeeksBetweenByWeekDay,
  addDays,
  isDateBetween,
  isDateGap,
  isSame,
  subFlightWidthCalculation,
  toISOString
} from 'Helpers/calendarHelper'
import {
  defineDefaultFlight,
  calculateFlightMerge,
  calculateSubFlightMerge,
  getSortableValue,
  getMergeFlightDates,
  getValueOfCorrectType,
  getMergeFlightDatesByMonth
} from 'Helpers/flightHelper'
import {
  getFlightColumNames,
  getFlightColumnNames,
  getFlightGroupNames,
  getMergeFlights,
  IPasteObject,
  parseDataType
} from 'Helpers/pasteHelper'
import { compare } from 'Helpers/sortHelper'
import { findDifference } from 'Helpers/objectHelper'
import { getFieldValuesByLevelId } from 'Helpers/redistributionHelpers'
import * as actionTypes from 'Constants/actionTypes'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { CalculationMessage } from 'Constants/enums/CalculationMessages'
import { ChosenFlightField, CalculationFieldResult, MediaPlanVersionForList, CalculationResults, SubtotalConfiguration, SubtotalCalculationResult } from 'Apis/generated/mediaPlanVersionsApi'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { RedistributionPostPut, Redistribution } from 'Apis/generated/redistributionsApi'
import { fromMediaPlansToLookUpPlans, ILookupPlan, IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import {
  buildEmptyFlightGrid,
  buildEmptySubFlights,
  CalendarView,
  IMediaPlanVersion,
  INewUniqueString,
  INewUniqueStringObject,
  initialiseMediaPlanVersionFlights,
  sanitiseMediaPlanVersionFlightsRevised,
  setMomentLocale
} from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { IValidationResult, parseCalculationResult } from 'Components/MediaPlanVersion/constants/entities/ICalculationResult'
import {
  fieldsInPlanByLevel,
  findByFieldLevelId,
  getFieldColumnName,
  getFieldDataTypeId,
  getSortedFlightFields,
  getSortedFlightGroupFields,
  IMediaPlanMetaField,
  IMediaPlanTemplateFields,
  IMediaPlanVersionField,
  isAggregatedOrCalculated,
  isFieldAllowForCalculation,
  isFieldAvailable
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightDate } from 'Components/MediaPlanVersion/constants/entities/IFlightDate'
import { IGoalSeekData, WizardProgress } from 'Components/GoalSeek/constants/entities/IGoalSeekData'
import { ISortItem } from 'Components/MediaPlanVersion/constants/entities/ISortItem'
import { IApplyRedistribution, IRedistribution, ISelectedRedistributeByField } from 'Components/Redistribution/constants/entities/IRedistribution'
import { createDefaultFlightGroup, IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IFlight, cloneFlight, cloneSubFlightShiftingDates } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import * as arrayHelpers from 'Helpers/arrayHelper'
import { expandAllFlightGroups } from './appActions'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

interface IGetMediaPlansAction {
  type: actionTypes.GET_MEDIA_PLANS
  payload: IMediaPlans[]
}

interface IGetMediaPlanVersionById {
  type: actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID
  payload: IMediaPlanVersion
}

interface ISetMediaPlanVersionUnmodified {
  type: actionTypes.SET_MEDIA_PLAN_VERSION_UNMODIFIED
  payload: IMediaPlanVersion
}

interface ISyncMediaPlanVersionUnmodified {
  type: actionTypes.SYNC_MEDIA_PLAN_VERSION_UNMODIFIED
}

interface IResetMediaPlanVersionUnmodified {
  type: actionTypes.RESET_MEDIA_PLAN_VERSION_UNMODIFIED
}

interface IDeleteMediaPlan {
  type: actionTypes.DELETE_MEDIA_PLAN
}

interface IRemoveMediaPlanFromReporting {
  type: actionTypes.REMOVE_MEDIA_PLAN_FROM_REPORTING
}

interface ISetSelectedCalculatedField {
  type: actionTypes.SET_SELECTED_CALCULATED_FIELD
  payload: IMediaPlanMetaField
}

interface ISetPlanFieldValue {
  type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE
  payload: any
}

interface ISetPlanFlightGroupValue {
  type: actionTypes.SET_PLAN_FLIGHT_GROUP_VALUE
  payload: any
  flightGroupIndex: number
}

interface ISetPlanFlightValue {
  type: actionTypes.SET_PLAN_FLIGHT_LEVEL_VALUE
  payload: any
  flightGroupIndex: number
  flightIndex: number
}

interface ISetPlanSubFlightValue {
  type: actionTypes.SET_PLAN_SUB_FLIGHT_LEVEL_VALUE
  payload: any
  flightGroupIndex: number
  flightIndex: number
  subFlightIndex: number
}

interface ISetPlanErrors {
  type: actionTypes.SET_PLAN_ERRORS
  payload: CalculationFieldResult[]
}

interface ICalculationsRunning {
  type: actionTypes.CALCULATIONS_RUNNING
  payload: boolean
}

interface ISetCalculationIssues {
  type: actionTypes.CALCULATION_ISSUES
  payload: boolean
}

interface IAddPlanFlightGroup {
  type: actionTypes.ADD_PLAN_FLIGHT_GROUP
  payload: any
}

interface ICollapseFlightGroup {
  type: actionTypes.COLLAPSE_FLIGHT_GROUP
  payload: number
}

interface ICollapseAllFlightGroupRows {
  type: actionTypes.COLLAPSE_ALL_FLIGHT_GROUP_ROWS
  payload: IFlightGroup[]
}

interface IExpandAllFlightGroupRows {
  type: actionTypes.EXPAND_ALL_FLIGHT_GROUP_ROWS
}

interface IInsertFlightGroups {
  type: actionTypes.INSERT_FLIGHT_GROUPS
  payload: {
    flightGroups: Array<Partial<IFlightGroup>>
    insertAt: number
  }
}

interface IUpsertFlightGroups {
  type: actionTypes.UPSERT_FLIGHT_GROUPS
  payload: {
    flightGroups: Array<Partial<IFlightGroup>>
    flightGroupFields: IMediaPlanTemplateFields[]
    flightGroupSelection: Partial<IFlightGroup>
  }
}

interface IDuplicateFlightGroup {
  type: actionTypes.DUPLICATE_FLIGHT_GROUP
  payload: number
}

interface IDeleteFlightGroup {
  type: actionTypes.DELETE_FLIGHT_GROUP
  payload: number
}

interface IDeleteFlightPlan {
  type: actionTypes.DELETE_MEDIAPLAN_FLIGHT
  flightGroupIndex: number
  flightIndex: number
  flights: any[]
}

interface IDeleteSubFlightPlan {
  type: actionTypes.DELETE_MEDIAPLAN_SUB_FLIGHT
  flightGroupIndex: number
  flightIndex: number
  subFlightIndex: number
  subFlights: any[]
}

interface IResetFlightGroups {
  type: actionTypes.RESET_FLIGHT_GROUPS
  payload: { [key: number]: Partial<IFlightGroup> }
}

interface IMergeFlightPlan {
  type: actionTypes.MERGE_FLIGHT_PLAN
  flightGroupIndex: number
  flights: any[]
}

interface IMergeSubFlightPlan {
  type: actionTypes.MERGE_SUB_FLIGHT_PLAN
  flightGroupIndex: number
  flights: any[]
}

interface ISetSelectedFlights {
  type: actionTypes.SET_SELECTED_FLIGHTS
  payload: string[]
}

interface ISetSelectedDated {
  type: actionTypes.SET_SELECTED_DATES
  payload: string[]
}

interface ICopySelectedFlight {
  type: actionTypes.COPY_FLIGHT
  payload: IFlight | null
}

interface ISetFlightPasteModalStartDate {
  type: actionTypes.SET_FLIGHT_PASTE_MODAL_START_DATE
  payload: string
}

interface IBuildFlightGrid {
  type: actionTypes.BUILD_FLIGHT_GRID
  payload: any[]
}

interface IAddAvailableField {
  type: actionTypes.ADD_AVAILABLE_FIELD
  payload: IMediaPlanVersionField
  flightGroupIndex?: number
}

interface IUpdateMediaPlanVersionFields {
  type: actionTypes.UPDATE_MEDIA_PLAN_VERSION_FIELDS
  payload: IMediaPlanVersionField[]
}

interface IAddChosenFlightField {
  type: actionTypes.ADD_CHOSEN_FLIGHT_FIELD
  payload: any
  flightGroupIndex: number
}

interface IDisabledApproval {
  type: actionTypes.DISABLED_APPROVAL
  payload: boolean
}

interface IDisabledVersionControl {
  type: actionTypes.DISABLED_VERSION_CONTROL
  payload: boolean
}

interface ISetMediaPlanHistory {
  type: actionTypes.SET_MEDIA_PLAN_HISTORY
  payload: MediaPlanVersionForList[]
}

interface IAddFlightTableView {
  type: actionTypes.ADD_FLIGHT_TABLE_VIEW
  payload: IFlightDate
  flightGroupIndex: number
}

interface IRemoveFlightTableView {
  type: actionTypes.REMOVE_FLIGHT_TABLE_VIEW
  payload: IFlightDate
  flightGroupIndex: number
}

interface IDuplicateFlightTableView {
  type: actionTypes.DUPLICATE_FLIGHT_TABLE_VIEW
  payload: IFlightDate
  flightGroupIndex: number
}

interface IValidateOverlapFlights {
  type: actionTypes.VALIDATE_OVERLAP_FLIGHTS
  flightGroupIndex: number
}

interface IValidateFlightDate {
  type: actionTypes.VALIDATE_FLIGHT_DATE
  flightGroupIndex: number
  flightIndex: number
}

interface ICleanFlightGroupWarnings {
  type: actionTypes.CLEAN_FLIGHT_GROUP_WARNINGS
}

interface ICleanInvalidFlights {
  type: actionTypes.CLEAN_INVALID_FLIGHTS
}

interface ICleanFlightGroupWidths {
  type: actionTypes.CLEAN_FLIGHT_GROUP_WIDTHS
}

interface IWeekModeEnabled {
  type: actionTypes.WEEK_MODE_ENABLED
  payload: boolean
}

interface ISetCurrentMediaPlan {
  type: actionTypes.SET_CURRENT_MEDIA_PLAN
  payload: IMediaPlans
}

interface ISaveNewUniqueString {
  type: actionTypes.SAVE_NEW_UNIQUE_STRING
  payload: INewUniqueStringObject
}

interface IToggleCalculations {
  type: actionTypes.TOGGLE_CALCULATIONS
  payload: boolean
}

interface IPasteFlightFieldValue {
  type: actionTypes.PASTE_FLIGHT_TABLE_VALUE
  payload: any
  flightGroupIndex: number
  flightIndex: number
}

interface IUpdateGoalSeekModalState {
  type: actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE
  payload: IGoalSeekData
}

interface IGetGoalSeekData {
  type: actionTypes.GET_GOAL_SEEK_DATA
  payload: CalculationResults
}

interface ISetGoalSeekModalVisible {
  type: actionTypes.TOGGLE_GOAL_SEEK_MODAL_VISIBLE
}

interface IGetRedistributions {
  type: actionTypes.GET_REDISTRIBUTIONS
  payload: Redistribution[]
}

interface IUpdateRedistribution {
  type: actionTypes.UPDATE_REDISTRIBUTION
  payload: Redistribution
}

interface ISetCurrentRedistribution {
  type: actionTypes.SET_CURRENT_REDISTRIBUTION
  payload: IRedistribution
}

interface IDeleteRedistribution {
  type: actionTypes.DELETE_REDISTRIBUTION
  payload: number
}

interface ISetSelectedRedistributeByField {
  type: actionTypes.SET_SELECTED_REDISTRIBUTE_BY_FIELD
  payload: ISelectedRedistributeByField
}

interface IRemoveSelectedRedistributeByField {
  type: actionTypes.REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD
  payload: number
}

interface IClearSelectedRedistributeByFields {
  type: actionTypes.CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS
}

interface ISetRedistributeModalVisible {
  type: actionTypes.SET_REDISTRIBUTE_MODAL_VISIBLE
  payload: boolean
}

interface ISetCalculationMessageKey {
  type: actionTypes.SET_CALCULATION_MESSAGE_KEY
  payload: number | string
}

interface IExpandFlightGroupRow {
  type: actionTypes.EXPAND_FLIGHT_GROUP_ROW
  payload: number
}

interface IReplaceSingleFlightGroupSelection {
  type: actionTypes.REPLACE_SINGLE_FLIGHT_GROUP_SELECTION
  payload: Partial<IFlightGroup>
  flightGroupId: number
}

interface IReplaceFlightGroupsSelection {
  type: actionTypes.REPLACE_FLIGHT_GROUPS_SELECTION
  payload: Partial<IFlightGroup>
}

interface IClearFlightGroupSelection {
  type: actionTypes.CLEAR_FLIGHT_GROUP_SELECTION
}

interface ISetPlanSubtotals {
  type: actionTypes.SET_PLAN_SUBTOTALS
  payload: SubtotalCalculationResult
}

interface ISetSubtotalsConfigurations {
  type: actionTypes.SET_SUBTOTAL_CONFIGURATIONS
  payload: SubtotalConfiguration
}

interface IRemoveMediaPlanFromCoreM {
  type: actionTypes.REMOVE_MEDIA_PLAN_FROM_COREM
}

export type MediaPlansActions =
  | IGetMediaPlansAction
  | IGetMediaPlanVersionById
  | ISetMediaPlanVersionUnmodified
  | ISyncMediaPlanVersionUnmodified
  | IResetMediaPlanVersionUnmodified
  | IDeleteMediaPlan
  | IRemoveMediaPlanFromReporting
  | ISetSelectedCalculatedField
  | ISetPlanFieldValue
  | ISetPlanFlightGroupValue
  | ISetPlanFlightValue
  | ISetPlanSubFlightValue
  | ISetPlanErrors
  | IAddPlanFlightGroup
  | ICollapseFlightGroup
  | ICollapseAllFlightGroupRows
  | IExpandAllFlightGroupRows
  | IInsertFlightGroups
  | IUpsertFlightGroups
  | IDuplicateFlightGroup
  | IDeleteFlightGroup
  | IDeleteFlightPlan
  | IMergeFlightPlan
  | ISetSelectedDated
  | ISetSelectedFlights
  | ICopySelectedFlight
  | ISetFlightPasteModalStartDate
  | IDeleteSubFlightPlan
  | IResetFlightGroups
  | IMergeSubFlightPlan
  | IBuildFlightGrid
  | IAddAvailableField
  | IUpdateMediaPlanVersionFields
  | IAddChosenFlightField
  | IDisabledApproval
  | IDisabledVersionControl
  | ISetMediaPlanHistory
  | IAddFlightTableView
  | IRemoveFlightTableView
  | IDuplicateFlightTableView
  | IValidateOverlapFlights
  | IValidateFlightDate
  | ICleanFlightGroupWarnings
  | ICleanInvalidFlights
  | ICleanFlightGroupWidths
  | IWeekModeEnabled
  | ISetCurrentMediaPlan
  | ISaveNewUniqueString
  | IToggleCalculations
  | IPasteFlightFieldValue
  | IUpdateGoalSeekModalState
  | IGetGoalSeekData
  | ISetGoalSeekModalVisible
  | ISetCalculationIssues
  | ICalculationsRunning
  | IGetRedistributions
  | IUpdateRedistribution
  | ISetCurrentRedistribution
  | IDeleteRedistribution
  | ISetSelectedRedistributeByField
  | IRemoveSelectedRedistributeByField
  | IClearSelectedRedistributeByFields
  | ISetRedistributeModalVisible
  | ISetCalculationMessageKey
  | IExpandFlightGroupRow
  | IReplaceSingleFlightGroupSelection
  | IReplaceFlightGroupsSelection
  | IClearFlightGroupSelection
  | ISetPlanSubtotals
  | ISetSubtotalsConfigurations
  | IRemoveMediaPlanFromCoreM

export const getMediaPlans = (
  clientId: string | number,
  hierarchies: IMSHierarchies,
  geographyHierarchyId?: number,
  mediaHierarchyId?: number,
  businessHierarchyId?: number,
  brandHierarchyId?: number
) => async (dispatch) => {
  const url = `mediaPlans?clientId=${clientId}` +
    (geographyHierarchyId ? `&geographyHierarchyId=${geographyHierarchyId}` : '') +
    (mediaHierarchyId ? `&mediaHierarchyId=${mediaHierarchyId}` : '') +
    (businessHierarchyId ? `&businessHierarchyId=${businessHierarchyId}` : '') +
    (brandHierarchyId ? `&brandHierarchyId=${brandHierarchyId}` : '')
  const mediaPlans = await mediaPlanService.get(url)
  const lookupPlans = fromMediaPlansToLookUpPlans(mediaPlans, hierarchies)
  dispatch({
    type: actionTypes.GET_MEDIA_PLANS,
    payload: lookupPlans
  })
}

export const initialisePlanListContainer = async (done, dispatch, clientId: number, hierarchies: Partial<IMSHierarchies>, props) => {
  const {
    geographyHierarchyId,
    mediaHierarchyId,
    brandHierarchyId,
    businessHierarchyId
  } = props
  try {
    const mediaPlans = await mediaPlanService.getMediaPlans(clientId, geographyHierarchyId, mediaHierarchyId, businessHierarchyId, brandHierarchyId)
    const lookupPlans = fromMediaPlansToLookUpPlans(mediaPlans, hierarchies)
    dispatch({
      type: actionTypes.GET_MEDIA_PLANS,
      payload: lookupPlans
    })
    done()
  } catch (error) {
    done('The Plan List failed to load. Please try refreshing. If that fails, contact administrator.')
  }
}

export const setMediaPlanVersionById = (mediaPlanVersionId: number, clientId: number, getMediaPlan?: boolean, url?: string) => async (dispatch, getState: () => RootState) => {
  url = url || `mediaPlanVersions/${mediaPlanVersionId}?clientId=${clientId}`
  const payload: IMediaPlanVersion = await mediaPlanService.get(url)
  const initializedFlightsPayload = initialiseMediaPlanVersionFlights(payload)
  const { parseData } = initializedFlightsPayload

  if (getMediaPlan) {
    const mediaPlans = getState().mediaPlans.mediaPlans
    let currentMediaPlan = mediaPlans.find(mp => mp.mediaPlanId === parseData.mediaPlanId)

    if (!currentMediaPlan) {
      currentMediaPlan = await mediaPlanService.get(`mediaPlans/${parseData.mediaPlanId}?clientId=${clientId}`)
    }

    dispatch({
      type: actionTypes.SET_CURRENT_MEDIA_PLAN,
      payload: currentMediaPlan
    })
  }

  dispatch(updateMediaPlanStore(initializedFlightsPayload))
  dispatch(updateUnmodifiedMediaPlanStore(initializedFlightsPayload))

  return true
}

export const syncMediaPlanVersionFromTemplate = (mediaPlanVersionId: number) =>
  async (dispatch, getState): Promise<{ diff: Partial<IMediaPlanVersion>; error: string }> => {
    const clientId = getState().app.currentClient.id
    const currentMediaPlanVersion: IMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
    let mediaPlanVersionWithDataString: IMediaPlanVersion

    try {
      mediaPlanVersionWithDataString = await mediaPlanService.get(`mediaPlanVersions/${mediaPlanVersionId}/syncFromTemplate?clientId=${clientId}`)
    } catch (error) {
      return { diff: null, error }
    }

    mediaPlanVersionWithDataString = initialiseMediaPlanVersionFlights(mediaPlanVersionWithDataString)
    const syncDifference = findDifference(currentMediaPlanVersion, mediaPlanVersionWithDataString)

    if (syncDifference) {
      const updatedSubtotalConfigurationFields = mediaPlanVersionWithDataString.mediaPlan.subtotalConfiguration?.subtotalConfigurationFields?.filter(oldConfig =>
        mediaPlanVersionWithDataString.mediaPlanVersionFields.some(updatedField =>
          updatedField.clientMediaPlanField.clientMediaPlanFieldId === oldConfig.clientMediaPlanFieldId
        )
      )
      const updatedSubtotalConfiguration = mediaPlanVersionWithDataString.mediaPlan?.subtotalConfiguration ? {
        ...mediaPlanVersionWithDataString.mediaPlan.subtotalConfiguration,
        subtotalConfigurationFields: updatedSubtotalConfigurationFields
      } : null
      dispatch({
        type: actionTypes.SET_CURRENT_MEDIA_PLAN,
        payload: {
          ...mediaPlanVersionWithDataString.mediaPlan,
          subtotalConfiguration: updatedSubtotalConfiguration
        }
      })
      dispatch(updateMediaPlanStore({
        ...mediaPlanVersionWithDataString,
        mediaPlan: {
          ...mediaPlanVersionWithDataString.mediaPlan,
          subtotalConfiguration: updatedSubtotalConfiguration
        }
      }))
      await dispatch(runCalculationOnBlur())
    }

    return { diff: syncDifference, error: null }
  }

export const updateMediaPlanFieldsRevised = (mediaPlanVersionId: number, clientId: number, selectedCalculatedField: IMediaPlanMetaField) =>
  async (dispatch: Dispatch<MediaPlansActions>, getState: () => RootState): Promise<CalculationResults> => {
    try {
      const currentMediaPlan = { ...getState().mediaPlans.currentMediaPlanVersion }
      const mediaPlanVersion = sanitiseMediaPlanVersionFlightsRevised(currentMediaPlan)
      const mediaPlanIndex = mediaPlanVersion.mediaPlanVersionFields.findIndex(mf => mf.clientMediaPlanFieldId === selectedCalculatedField.clientMediaPlanFieldId)
      const newMediaPlan = {
        ...mediaPlanVersion,
        mediaPlanVersionFields: mediaPlanVersion.mediaPlanVersionFields.map((mf, i) =>
          i === mediaPlanIndex ? { ...mf, calculation: selectedCalculatedField.calculation, functionId: selectedCalculatedField.functionId } : mf
        )
      }
      dispatch({ type: actionTypes.CALCULATIONS_RUNNING, payload: true })
      const url = `mediaPlanVersions/${mediaPlanVersionId}/setcalculations?clientId=${clientId}`
      const payload: CalculationResults = await mediaPlanService.putJson(url, newMediaPlan)
      // If there are calculation errors do not update current plan
      const mediaPlan = payload.topLevelErrors.length > 0 ? mediaPlanVersion : newMediaPlan
      const parseData = parseCalculationResult(payload, { ...currentMediaPlan, mediaPlanVersionFields: mediaPlan.mediaPlanVersionFields })
      dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: payload.fieldResults })
      dispatch({ type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE, payload: { calculatorHash: payload.calculatorHash } })
      dispatch({ type: actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID, payload: { ...mediaPlan, parseData } })
      return payload
    } catch (error) {
      return { topLevelErrors: ['There was an internal error'], fieldResults: [] }
    } finally {
      dispatch({ type: actionTypes.CALCULATIONS_RUNNING, payload: false })
    }
  }

export const disablePlanButtons = () => dispatch => {
  batch(() => {
    dispatch({ type: actionTypes.DISABLED_APPROVAL, payload: true })
    dispatch({ type: actionTypes.DISABLED_VERSION_CONTROL, payload: true })
  })
}

export const enablePlanButtons = () => dispatch => {
  batch(() => {
    dispatch({ type: actionTypes.DISABLED_APPROVAL, payload: false })
    dispatch({ type: actionTypes.DISABLED_VERSION_CONTROL, payload: false })
  })
}

export const resetUnmodifiedMediaPlanVersion = () => dispatch => {
  dispatch({ type: actionTypes.RESET_MEDIA_PLAN_VERSION_UNMODIFIED })
}

const runCalculations = mediaPlanService.msQueuedDebounce(
  async (dispatch: Dispatch<MediaPlansActions>, getState: () => RootState) => {
    const { enableCalculations } = { ...getState().mediaPlans }
    let mediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }

    if (enableCalculations) {
      try {
        dispatch({ type: actionTypes.CALCULATIONS_RUNNING, payload: true })
        const mediaPlanVersionWithValidFlights = sanitiseMediaPlanVersionFlightsRevised(mediaPlanVersion)
        const result = await mediaPlanService.runCalculationNoSave(getState().app.currentClient.id, mediaPlanVersionWithValidFlights)
        mediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }

        dispatch({ type: actionTypes.SET_PLAN_SUBTOTALS, payload: result.subtotals })
        if (mediaPlanVersion && mediaPlanVersion.parseData) {
          const parseData = parseCalculationResult(result, mediaPlanVersion)
          dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: result.fieldResults })
          dispatch({ type: actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID, payload: { ...mediaPlanVersion, parseData } })

          if (result.compilationError && result.topLevelErrors.length > 0) {
            Modal.error({ title: 'Calculations Failed', content: result.topLevelErrors.join(', ') })
            dispatch({ type: actionTypes.CALCULATION_ISSUES, payload: true })
          } else {
            dispatch({ type: actionTypes.CALCULATION_ISSUES, payload: false })
          }
          return result
        }
      } catch (err) {
        return { topLevelErrors: ['There was an internal error'], fieldResults: [] }
      } finally {
        dispatch({ type: actionTypes.CALCULATIONS_RUNNING, payload: false })
      }
    }
  }
)

export const runCalculationOnBlur = (msg?: string): ThunkAction<void, RootState, unknown, Action> => async (dispatch, getState: () => RootState) => {
  const calculationMessageKey = getState().mediaPlans.calculationMessageKey
  const newMessageKey = Math.random()

  const timeout = setTimeout(() => {
    if (calculationMessageKey) { // to prevent a message stack, we need to remove previous one
      message.destroy(calculationMessageKey)
    }
    dispatch({ type: actionTypes.SET_CALCULATION_MESSAGE_KEY, payload: newMessageKey })
    message.loading({
      content: msg || 'Running Calculation',
      duration: 20,
      key: newMessageKey
    })
  }, 1500)

  const result = await dispatch(runCalculations)
  message.destroy(newMessageKey)
  clearTimeout(timeout)
  return result
}


export const updateMediaPlanRevised = (clientId: number, mediaPlanVersionId: number, isTemplateUpdated: boolean) => async (dispatch, getState: () => RootState): Promise<string> => {
  try {
    await dispatch(disablePlanButtons())
    await dispatch(runCalculationOnBlur(CalculationMessage.UpdateMediaPlanRevised))
    const currentClientMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
    const mediaPlanVersionWithValidFlights = sanitiseMediaPlanVersionFlightsRevised(currentClientMediaPlanVersion)
    const payload: IMediaPlanVersion = await mediaPlanService.putJson(
      `mediaPlanVersions/${mediaPlanVersionId}?clientId=${clientId}&isTemplateUpdate=${isTemplateUpdated}`,
      mediaPlanVersionWithValidFlights
    )
    const initializedFlightsPayload = initialiseMediaPlanVersionFlights(payload)

    batch(() => {
      dispatch(updateMediaPlanStore(initializedFlightsPayload))
      dispatch(updateUnmodifiedMediaPlanStore(initializedFlightsPayload))
      dispatch(enablePlanButtons())
      dispatch({
        type: actionTypes.WEEK_MODE_ENABLED,
        payload: true
      })
    })

    return null
  } catch (error) {
    dispatch(enablePlanButtons())
    if (error.httpResponseBody) {
      if (error.httpResponseBody.fieldResults) {
        dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: error.httpResponseBody.fieldResults })
      }
      const errorMessage = error.httpResponseBody.fieldResults && Array.isArray(error.httpResponseBody.fieldResults)
        ? error.httpResponseBody.fieldResults.map((e) => e.error).join('\r\n')
        : error.httpResponseBody

      return errorMessage
    } else {
      return 'There was an internal error'
    }
  }
}

export const saveMediaPlanVersionRevised = (clientId: number) => async (dispatch, getState: () => RootState): Promise<IMediaPlanVersion | string> => {
  dispatch(disablePlanButtons())
  try {
    const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
    const mediaPlanVersionWithValidFlights = sanitiseMediaPlanVersionFlightsRevised(currentMediaPlanVersion)
    const payload: IMediaPlanVersion = await mediaPlanService.postJson(`mediaPlanVersions?clientId=${clientId}`, mediaPlanVersionWithValidFlights)
    const initializedFlightsPayload = initialiseMediaPlanVersionFlights(payload)

    dispatch(updateMediaPlanStore(initializedFlightsPayload))
    dispatch(updateUnmodifiedMediaPlanStore(initializedFlightsPayload))
    await dispatch(runCalculationOnBlur(CalculationMessage.SaveMediaPlanVersionRevised))
    dispatch(enablePlanButtons())
    dispatch({
      type: actionTypes.WEEK_MODE_ENABLED,
      payload: true
    })
    return payload
  } catch (error) {
    dispatch(enablePlanButtons())
    if (error.httpResponseBody) {
      return error.httpResponseBody
    } else {
      return 'There was an internal error'
    }
  }
}

export const updateMediaPlanStore = (mediaPlanVersion: IMediaPlanVersion) => dispatch => {
  dispatch({ type: actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID, payload: mediaPlanVersion })
}

export const updateUnmodifiedMediaPlanStore = (mediaPlanVersion: IMediaPlanVersion) => dispatch => {
  dispatch({ type: actionTypes.SET_MEDIA_PLAN_VERSION_UNMODIFIED, payload: mediaPlanVersion })
}

export const updatePlanLevelValue = (newPlanLevelValue: any) => dispatch => {
  dispatch({ type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE, payload: newPlanLevelValue })
}

export const updatePlanFlightGroupValue = (newFlightGroupValue: any, flightGroupIndex: number) => (dispatch, getState: () => RootState) => {
  if (newFlightGroupValue.hasOwnProperty('sortOrder')) {
    const hasThisSortOrderAlready = getState().mediaPlans.currentMediaPlanVersion.parseData.flightGroups.some(flightGroup => flightGroup.sortOrder === newFlightGroupValue['sortOrder'])

    if (!hasThisSortOrderAlready) {
      dispatch({ type: actionTypes.SET_PLAN_FLIGHT_GROUP_VALUE, payload: newFlightGroupValue, flightGroupIndex })
    } else {
      dispatch({ type: actionTypes.SET_PLAN_FLIGHT_GROUP_VALUE, payload: { sortOrder: null }, flightGroupIndex })
    }
  } else {
    dispatch({ type: actionTypes.SET_PLAN_FLIGHT_GROUP_VALUE, payload: newFlightGroupValue, flightGroupIndex })
  }
}

export const updatePlanFlightValue = (newPlanLevelValue: any, flightGroupIndex: number, flightIndex: number) => dispatch => {
  dispatch({ type: actionTypes.SET_PLAN_FLIGHT_LEVEL_VALUE, payload: newPlanLevelValue, flightGroupIndex, flightIndex })
}

export const setSelectedDates = (selectedDates: string[]) => (dispatch, getState: () => RootState) => {
  const existingSelectedDates = getState().mediaPlans.selectedDates
  if (JSON.stringify(existingSelectedDates) === JSON.stringify(selectedDates)) {
    return
  }

  dispatch({ type: actionTypes.SET_SELECTED_DATES, payload: selectedDates })
}

export const copyFlight = (flight?: IFlight | null) => (dispatch, getState: () => RootState) => {
  const existingCopiedFlight = getState().mediaPlans.copiedFlight
  if (flight === null && existingCopiedFlight === null) {
    return
  }

  dispatch({ type: actionTypes.COPY_FLIGHT, payload: flight })
}

export const setFlightPasteModalStartDate = (date: string) => dispatch => {
  dispatch({ type: actionTypes.SET_FLIGHT_PASTE_MODAL_START_DATE, payload: date })
}

export const pasteFlight = (startDate: string, targetFlightGroupIndex: number) => async (dispatch, getState: () => RootState) => {
  const { flightGroupIndex, flightIndex: copiedFlightIndex } = getState().mediaPlans.copiedFlight
  const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
  const copiedFlight: IFlight = currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex].flights[copiedFlightIndex]

  const flightDaysDuration = differenceInCalendarDays(new Date(copiedFlight.flightEndDate), new Date(copiedFlight.flightStartDate))
  const endDate = addDays(startDate, flightDaysDuration)

  const targetFlightIndex = currentMediaPlanVersion.parseData.flightGroups[targetFlightGroupIndex].flights.findIndex(
    (flight: IFlight) => isDateBetween(flight.flightStartDate, flight.flightEndDate, startDate)
  )
  const targetFlight: IFlight = currentMediaPlanVersion.parseData.flightGroups[targetFlightGroupIndex].flights[targetFlightIndex]

  await doMergeFlight(getState, new Date(startDate), endDate, targetFlightGroupIndex, targetFlight, dispatch)
  dispatch({ type: actionTypes.SET_FLIGHT_PASTE_MODAL_START_DATE, payload: false })

  const newFlightIndex = targetFlight.flightStartDate === startDate
    ? targetFlightIndex
    : targetFlightIndex + (
      Math.ceil(differenceInCalendarDays(new Date(startDate), new Date(targetFlight.flightStartDate)) / 7)
    )

  const copiedFlightValues = {
    ...omit(cloneFlight(copiedFlight), [
      'merge',
      'width',
      'flightStartDate',
      'flightEndDate'
    ]),
    mediaPlanFlightGroupId: targetFlight.mediaPlanFlightGroupId
  }

  const numberOfDaysShift = Math.floor(differenceInCalendarDays(new Date(startDate), new Date(copiedFlight.flightStartDate)))
  const newSubFlights = copiedFlightValues.subFlights.map(subFlight => cloneSubFlightShiftingDates(subFlight, numberOfDaysShift))

  dispatch(updatePlanFlightValue({
    ...copiedFlightValues,
    subFlights: newSubFlights
  }, targetFlightGroupIndex, newFlightIndex))

  dispatch(runCalculationOnBlur())
}

export const setSelectedFlights = (selectedFlights: string[]) => (dispatch, getState: () => RootState) => {
  const existingSelectedFlights = getState().mediaPlans.selectedFlights
  if (JSON.stringify(existingSelectedFlights) === JSON.stringify(selectedFlights)) {
    return
  }

  dispatch({ type: actionTypes.SET_SELECTED_FLIGHTS, payload: selectedFlights })
}

export const updatePlanSubFlightValue = (newPlanLevelValue: any, flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => dispatch => {
  dispatch({ type: actionTypes.SET_PLAN_SUB_FLIGHT_LEVEL_VALUE, payload: newPlanLevelValue, flightGroupIndex, flightIndex, subFlightIndex })
}

export const addFlightGroup = (flightGroupFields: IMediaPlanMetaField[]): ThunkAction<void, RootState, unknown, Action> =>
  (dispatch, getState: () => RootState) => {
    const mediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
    const currentFlightGroup = createDefaultFlightGroup(flightGroupFields, mediaPlanVersion)
    const hasSubtotals = !!mediaPlanVersion.mediaPlan?.subtotalConfiguration
    const hasCalculatedFields = mediaPlanVersion.mediaPlanVersionFields.filter(c => isFieldAllowForCalculation(c)).filter(c => isAggregatedOrCalculated(c)).length > 0

    dispatch({ type: actionTypes.ADD_PLAN_FLIGHT_GROUP, payload: currentFlightGroup })
    if (hasSubtotals || hasCalculatedFields) {
      dispatch(runCalculationOnBlur(CalculationMessage.AddFlightGroup))
    }
  }

export const insertFlightGroups = (payload: IInsertFlightGroups['payload']) => dispatch => {
  dispatch({ type: actionTypes.INSERT_FLIGHT_GROUPS, payload })
  dispatch(runCalculationOnBlur(CalculationMessage.DuplicateFlightGroup))
}

export const upsertFlightGroups = (payload: IUpsertFlightGroups['payload']) => dispatch => {
  dispatch({ type: actionTypes.UPSERT_FLIGHT_GROUPS, payload })
  dispatch(runCalculationOnBlur(CalculationMessage.DuplicateFlightGroup))
}

export const duplicateFlightGroup = (payload: IDuplicateFlightGroup['payload']) => dispatch => {
  dispatch({ type: actionTypes.DUPLICATE_FLIGHT_GROUP, payload })
  dispatch(runCalculationOnBlur(CalculationMessage.DuplicateFlightGroup))
}

export const collapseFlightGroup = (flightGroupId: number) => dispatch => {
  dispatch({ type: actionTypes.COLLAPSE_FLIGHT_GROUP, payload: flightGroupId })
}

export const collapseAllFlightGroupRows = (subtotalGroups: IFlightGroup[]) => dispatch => {
  dispatch({ type: actionTypes.COLLAPSE_ALL_FLIGHT_GROUP_ROWS, payload: subtotalGroups })
}

export const expandAllFlightGroupRows = () => dispatch => {
  dispatch({ type: actionTypes.EXPAND_ALL_FLIGHT_GROUP_ROWS })
}

export const deleteFlightGroup = (flightGroupIndex: number) => dispatch => {
  dispatch({ type: actionTypes.DELETE_FLIGHT_GROUP, payload: flightGroupIndex })
  dispatch(runCalculationOnBlur(CalculationMessage.DeleteFlightGroup))
}

export const clearFlightData = (flightGroupIndex: number, flightIndex: number) => (dispatch, getState: () => RootState) => {
  const { mediaPlanVersionFields, parseData } = getState().mediaPlans.currentMediaPlanVersion

  const currentFlightGroup = parseData.flightGroups[flightGroupIndex]
  const currentFlights: IFlight[] = currentFlightGroup.flights
  const selectedFlight: IFlight = currentFlights[flightIndex]

  const clearedFlightFields = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT).reduce((newFields, flightField) => {
    return {
      ...newFields,
      [getFieldColumnName(flightField)]: getValueOfCorrectType(
        flightField?.defaultValue ?? null,
        getFieldDataTypeId(flightField)
      )
    }
  }, {})

  const newSubFlights = selectedFlight.subFlights.map(subFlight => {
    const subFlightLookedupField = fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.SUB_FLIGHT).find((field) => (
      field.clientMediaPlanFieldId === subFlight.clientMediaPlanFieldId
    ))
    if (!subFlightLookedupField) {
      return
    }

    return ({
      ...subFlight,
      [getFieldColumnName(subFlightLookedupField)]: getValueOfCorrectType(
        subFlightLookedupField?.defaultValue ?? null,
        getFieldDataTypeId(subFlightLookedupField)
      )
    })
  }).filter(Boolean)

  const newFlight = {
    ...selectedFlight,
    ...clearedFlightFields,
    subFlights: newSubFlights
  }

  const newFlights = arrayHelpers.update(currentFlights, flightIndex, newFlight)

  dispatch({ type: actionTypes.MERGE_FLIGHT_PLAN, flightGroupIndex, flights: newFlights })
  dispatch(runCalculationOnBlur(CalculationMessage.ClearFlightData))
}

export const deleteFlight = (flightGroupIndex: number, flightIndex: number) => (dispatch, getState: () => RootState) => {
  const { mediaPlanVersionFields, parseData } = getState().mediaPlans.currentMediaPlanVersion
  const currentFlightGroup = parseData.flightGroups[flightGroupIndex]
  const currentFlights = currentFlightGroup.flights
  const selectedFlight = currentFlights[flightIndex]

  const formatResult = buildEmptyFlightGrid(selectedFlight.flightStartDate,
    selectedFlight.flightEndDate,
    mediaPlanVersionFields,
    currentFlightGroup)
  const flights = flightWidthCalculation(formatResult)
  dispatch({ type: actionTypes.DELETE_MEDIAPLAN_FLIGHT, flightGroupIndex, flightIndex, flights })
  dispatch(runCalculationOnBlur(CalculationMessage.DeleteFlight))
}

export const resetFlightGroups = (flightGroupsSelection: IResetFlightGroups['payload']) => dispatch => {
  dispatch({ type: actionTypes.RESET_FLIGHT_GROUPS, payload: flightGroupsSelection })
}

export const deleteSubFlight = (flightGroupIndex: number, flightIndex: number, subFlightIndex: number) => (dispatch, getState: () => RootState) => {
  const { mediaPlanVersionFields, parseData } = getState().mediaPlans.currentMediaPlanVersion
  const currentFlightGroup = parseData.flightGroups[flightGroupIndex]
  const selectedSubFlight = currentFlightGroup.flights[flightIndex].subFlights[subFlightIndex]
  const formatResult = buildEmptySubFlights(selectedSubFlight.subFlightStartDate,
    selectedSubFlight.subFlightEndDate,
    mediaPlanVersionFields,
    currentFlightGroup).filter((sb) => sb.clientMediaPlanFieldId === selectedSubFlight.clientMediaPlanFieldId)
  const subFlights = subFlightWidthCalculation(formatResult)
  dispatch({ type: actionTypes.DELETE_MEDIAPLAN_SUB_FLIGHT, flightGroupIndex, flightIndex, subFlightIndex, subFlights })
  dispatch(runCalculationOnBlur(CalculationMessage.DeleteSubFlight))
}

export const setSelectedCalculatedField = (selectedCalculatedField: IMediaPlanMetaField) => dispatch =>
  dispatch({ type: actionTypes.SET_SELECTED_CALCULATED_FIELD, payload: selectedCalculatedField })

export const mergeFlight = (startDate: Date, endDate: Date, mergeByWeek: boolean, startDayOfWeek: string, flightGroupIndex: number, flightIndex: number, mergeByMonth: boolean) => (dispatch, getState: () => RootState) => {
  const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
  const selectedFlight = currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex].flights[flightIndex]

  if (mergeByWeek) {
    const flightData = getMergeFlightDates(startDate, endDate, startDayOfWeek)
    flightData.forEach(x => {
      doMergeFlight(getState, x.start, x.end, flightGroupIndex, selectedFlight, dispatch)
    })
  } else if (mergeByMonth) {
    const flightData = getMergeFlightDatesByMonth(startDate, endDate)
    flightData.forEach(x => {
      doMergeFlight(getState, x.start, x.end, flightGroupIndex, selectedFlight, dispatch)
    })
  } else {
    doMergeFlight(getState, startDate, endDate, flightGroupIndex, selectedFlight, dispatch)
  }
}

async function doMergeFlight (getState: () => RootState, startDate: Date, endDate: Date, flightGroupIndex: number, selectedFlight: any, dispatch: any) {
  const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
  const newFlights = calculateFlightMerge(
    currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex].flights,
    selectedFlight,
    startDate,
    endDate,
    currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex],
    currentMediaPlanVersion.parseData,
    currentMediaPlanVersion.mediaPlanVersionFields
  )

  /*
    After we calculate the merge we need to fill any gaps in the subflight
  */
  newFlights.forEach((f) => {
    if (f.subFlights) {
      if (f.subFlights.filter((c) => 'merge' in c ? c.merge : false).length > 0) {
        let emptySubFlights = []
        // the current subflights fields from the chosen flight fields
        const subFlights = findByFieldLevelId(currentMediaPlanVersion.mediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
        const chosenSubFlights = currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex].chosenFlightFields
          .map(c => subFlights.find(m => m.clientMediaPlanFieldId === c.clientMediaPlanFieldId))
          .filter(c => c)
        const newChosenClientFields = chosenSubFlights.map(sf => sf.clientMediaPlanFieldId)
        newChosenClientFields.forEach(c => {
          const subFlightsByClientId = f.subFlights.filter(sf => sf.clientMediaPlanFieldId === c && 'merge' in sf ? sf.merge : false)
          if (subFlightsByClientId.length > 0) {
            // Check for gaps between each subflight
            subFlightsByClientId.forEach((sf, subFlightIndex) => {
              if (subFlightIndex + 1 <= subFlightsByClientId.length - 1) {
                const nextStartDate = toISOString(moment.utc(sf.subFlightEndDate).add(1, 'day'))
                const nextEndDate = toISOString(moment.utc(subFlightsByClientId[subFlightIndex + 1].subFlightStartDate).subtract(1, 'day'))
                if (isDateGap(nextStartDate, nextEndDate) || isSame(new Date(nextStartDate), new Date(nextEndDate))) {
                  const missingFlights = buildEmptySubFlights(
                    nextStartDate,
                    nextEndDate,
                    currentMediaPlanVersion.mediaPlanVersionFields,
                    currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
                  )
                  emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === c))
                }
              }
            })

            // Check for gaps between the beginning of the flight to the first subflight
            if (isDateGap(
              toISOString(moment.utc(f.flightStartDate)),
              toISOString(moment.utc(subFlightsByClientId[0].subFlightStartDate))
            )) {
              const subFlightStartDate = toISOString(moment.utc(f.flightStartDate))
              const subFlightEndDate = toISOString(moment.utc(subFlightsByClientId[0].subFlightStartDate).subtract(1, 'day'))
              const missingFlights = buildEmptySubFlights(
                subFlightStartDate,
                subFlightEndDate,
                currentMediaPlanVersion.mediaPlanVersionFields,
                currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
              )
              emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === c))
            }
            // Check for gaps between the end of the last subflight to the end of the flight
            if (isDateGap(
              toISOString(moment.utc(subFlightsByClientId[subFlightsByClientId.length - 1].subFlightEndDate)),
              toISOString(moment.utc(f.flightEndDate))
            )) {
              const subFlightStartDate = toISOString(moment.utc(subFlightsByClientId[subFlightsByClientId.length - 1].subFlightEndDate).add(1, 'day'))
              const subFlightEndDate = toISOString(moment.utc(f.flightEndDate))
              const missingFlights = buildEmptySubFlights(
                subFlightStartDate,
                subFlightEndDate,
                currentMediaPlanVersion.mediaPlanVersionFields,
                currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
              )
              emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === c))
            }
          } else {
            // if no subflight just build empty subflights
            const nextStartDate = moment.utc(f.flightStartDate)
            const nextEndDate = moment.utc(f.flightEndDate)
            const missingFlights = buildEmptySubFlights(
              nextStartDate,
              nextEndDate,
              currentMediaPlanVersion.mediaPlanVersionFields,
              currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
            )
            emptySubFlights = emptySubFlights.concat(missingFlights.filter(ef => ef.clientMediaPlanFieldId === c))
          }
        })
        const totalSubFlights = [...f.subFlights.filter(c => 'merge' in c ? c.merge : false).concat(emptySubFlights)]
        // eslint-disable-next-line functional/immutable-data
        f.subFlights = [...totalSubFlights].sort((current, next) => moment.utc(next.subFlightStartDate).isBefore(moment.utc(current.subFlightStartDate)) ? 1 : -1)
      } else {
        // if no subflight just build empty subflights
        const nextStartDate = moment.utc(f.flightStartDate)
        const nextEndDate = moment.utc(f.flightEndDate)
        // eslint-disable-next-line functional/immutable-data
        f.subFlights = buildEmptySubFlights(
          nextStartDate,
          nextEndDate,
          currentMediaPlanVersion.mediaPlanVersionFields,
          currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
        )
      }
    } else {
      // if no subflight just build empty subflights
      const nextStartDate = moment.utc(f.flightStartDate)
      const nextEndDate = moment.utc(f.flightEndDate)
      // eslint-disable-next-line functional/immutable-data
      f.subFlights = buildEmptySubFlights(
        nextStartDate,
        nextEndDate,
        currentMediaPlanVersion.mediaPlanVersionFields,
        currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
      )
    }
  })

  return dispatch({ type: actionTypes.MERGE_FLIGHT_PLAN, flightGroupIndex, flights: newFlights })
}

export const mergeSubFlight =
  (startDate: Date, endDate: Date, mergeByWeek: boolean, startDayOfWeek: string, flightGroupIndex: number, flightIndex: number, mergeByMonth: boolean, clientMediaPlanFieldId: number) => (dispatch, getState: () => RootState) => {
    if (mergeByWeek) {
      const flightData = getMergeFlightDates(startDate, endDate, startDayOfWeek)

      flightData.forEach(x => {
        doMergeSubFlights(getState, clientMediaPlanFieldId, x.start, x.end, flightGroupIndex, flightIndex, dispatch)
      })
    } else if (mergeByMonth) {
      const flightData = getMergeFlightDatesByMonth(startDate, endDate)

      flightData.forEach(x => {
        doMergeSubFlights(getState, clientMediaPlanFieldId, x.start, x.end, flightGroupIndex, flightIndex, dispatch)
      })
    } else {
      doMergeSubFlights(getState, clientMediaPlanFieldId, startDate, endDate, flightGroupIndex, flightIndex, dispatch)
    }
  }

const doMergeSubFlights = (getState: () => RootState, clientMediaPlanFieldId: number, startDate: Date, endDate: Date, flightGroupIndex: number, flightIndex: number, dispatch: any) => {
  const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion

  const subFlights = calculateSubFlightMerge(
    currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex],
    clientMediaPlanFieldId,
    startDate,
    endDate,
    currentMediaPlanVersion.parseData,
    currentMediaPlanVersion.mediaPlanVersionFields
  )

  const uniqueSubFlights = uniqBy(subFlights, 'subFlightStartDate') as any[]
  const notSelected = currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex].flights
    .map(f => f.subFlights).filter(sb => sb).flat()
    .filter(c => c.clientMediaPlanFieldId !== clientMediaPlanFieldId)
  const newFlightsWithSubFlights = currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]
    .flights.map((flight) => {
      const subFlightInFlights = uniqueSubFlights.filter(subFlight => {
        if (subFlight.clientMediaPlanFieldId === clientMediaPlanFieldId &&
          moment(subFlight.subFlightStartDate).isSameOrAfter(moment(flight.flightStartDate)) &&
          moment(subFlight.subFlightEndDate).isSameOrBefore(moment(flight.flightEndDate))) {
          return subFlight
        }
      })

      const notSelectedByDate = notSelected.filter(subFlight => {
        if (moment(subFlight.subFlightStartDate).isSameOrAfter(moment(flight.flightStartDate)) &&
          moment(subFlight.subFlightEndDate).isSameOrBefore(moment(flight.flightEndDate))) {
          return subFlight
        }
      })

      const newSubFlights = notSelectedByDate.concat(subFlightInFlights)
      return { ...flight, subFlights: newSubFlights.length === 0 ? flight.subFlights : newSubFlights }
    })

  dispatch({ type: actionTypes.MERGE_SUB_FLIGHT_PLAN, flightGroupIndex, flights: newFlightsWithSubFlights })
}

export const cloneMediaPlan = (mediaPlan: ILookupPlan, clientId: number) => async dispatch => {
  const mediaPlanVersion: IMediaPlanVersion = await mediaPlanService.get(`mediaPlanVersions/${mediaPlan.mediaPlanVersionId}?clientId=${clientId}`)
  // eslint-disable-next-line functional/immutable-data
  mediaPlan.mediaPlanVersion = mediaPlanVersion

  const mediaPlanCopy = {
    ...mediaPlan,
    mediaPlanVersion: {
      ...mediaPlan.mediaPlanVersion,
      data: JSON.stringify({
        ...JSON.parse(mediaPlan.mediaPlanVersion.data),
        calculatorHash: null
      })
    }
  }

  // eslint-disable-next-line functional/immutable-data
  delete mediaPlanCopy.planClientMediaHierarchyValue
  // eslint-disable-next-line functional/immutable-data
  delete mediaPlanCopy.planClientGeographyHierarchyValue
  await createMediaPlan(mediaPlanCopy, clientId)
  dispatch({
    type: actionTypes.CLONE_MEDIA_PLAN
  })
}

export const deleteMediaPlan = (mediaPlanId: number, clientId: number) => async dispatch => {
  const mediaPlan = await mediaPlanService.get(`mediaPlans/${mediaPlanId}?clientId=${clientId}`)

  if (mediaPlan) {
    await mediaPlanService.putJson(`mediaPlans/${mediaPlan.mediaPlanId}?clientId=${clientId}`,
      {
        planYear: mediaPlan.planYear,
        deletedOn: new Date(0, 0, 0).toISOString()
      }
    )
    dispatch({
      type: actionTypes.DELETE_MEDIA_PLAN
    })
  }
}

export const removeMediaPlanFromReporting = (mediaPlanId: number, clientId: number) => async dispatch => {
  try {
    await mediaPlanService.putJson(`mediaPlans/${mediaPlanId}/deletePlanFromReporting?clientId=${clientId}`, {})
  } catch (err) {
    return err.httpResponseBody
  }
  dispatch({
    type: actionTypes.REMOVE_MEDIA_PLAN_FROM_REPORTING
  })
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildGrid = (planStartDate: string, planEndDate: string, calendarView: CalendarView, startDayOfWeek: string) => (dispatch, getState: () => RootState) => {
  const currentMediaPlanVersion = getState().mediaPlans.currentMediaPlanVersion
  const flightFields = findByFieldLevelId(currentMediaPlanVersion.mediaPlanVersionFields.filter(c => !isFieldAvailable(c) || c.isInPlan), FieldLevelType.FLIGHT)
  const subFlightFields = findByFieldLevelId(currentMediaPlanVersion.mediaPlanVersionFields.filter(c => !isFieldAvailable(c) || c.isInPlan), FieldLevelType.SUB_FLIGHT)
  setMomentLocale(startDayOfWeek)

  if (flightFields.length > 0) {
    const flightGroups = currentMediaPlanVersion.parseData.flightGroups.map((flightGroup: IFlightGroup) => {
      if (flightGroup.flights === null || (flightGroup.flights && flightGroup.flights.length === 0)) {
        return {
          ...flightGroup,
          flights: defineDefaultFlight(
            planStartDate,
            planEndDate,
            [...flightFields, ...subFlightFields],
            currentMediaPlanVersion
          )
        }
      } else if (!planStartDate || !planEndDate) {
        return flightGroup
      } else {
        const { flights: emptyFlights }: { flights: IFlight[] } = {
          ...flightGroup,
          flights: defineDefaultFlight(
            planStartDate,
            planEndDate,
            [...flightFields, ...subFlightFields],
            currentMediaPlanVersion
          )
        }

        const checkIsFlightWithinPlanDates = (f: IFlight) => (
          moment.utc(f.flightStartDate).isSameOrAfter(moment.utc(planStartDate), 'day') &&
          moment.utc(f.flightEndDate).isSameOrBefore(moment.utc(planEndDate), 'day')
        )
        const newFlights = flightGroup.flights.filter(checkIsFlightWithinPlanDates)

        if (!newFlights?.length) {
          return {
            ...flightGroup,
            flights: emptyFlights
          }
        }

        const contiguousFlightList = [
          ...emptyFlights.filter(f => moment.utc(f.flightEndDate).isBefore(moment.utc(newFlights[0].flightStartDate), 'day')),
          ...newFlights,
          ...emptyFlights.filter(f => moment.utc(f.flightStartDate).isAfter(moment.utc(newFlights.slice(-1)[0].flightEndDate), 'day'))
        ]

        const newFlightGroup = {
          ...flightGroup,
          flights: contiguousFlightList
        }
        return newFlightGroup
      }
    })
    dispatch({ type: actionTypes.BUILD_FLIGHT_GRID, payload: flightGroups })
    dispatch({ type: actionTypes.SYNC_MEDIA_PLAN_VERSION_UNMODIFIED })
  }
}

export const addAvailableField = (availableField: IMediaPlanMetaField, flightGroupIndex?: number) => dispatch =>
  dispatch({ type: actionTypes.ADD_AVAILABLE_FIELD, payload: availableField, flightGroupIndex })

export const addChosenFlightField = (flightGroupIndex: number, chosenFlightField: ChosenFlightField) => dispatch => {
  dispatch({ type: actionTypes.ADD_CHOSEN_FLIGHT_FIELD, payload: chosenFlightField, flightGroupIndex })
}

export const onSelectedAvailableField = (selectedAvailableField: IMediaPlanVersionField, flightGroupIndex?: number) => (dispatch, getState: () => RootState) => {
  const currentMediaPlan = getState().mediaPlans.currentMediaPlanVersion
  const newMediaPlanVersionFields = currentMediaPlan.mediaPlanVersionFields.map((m) => {
    if (selectedAvailableField.mediaPlanVersionFieldId === m.mediaPlanVersionFieldId) {
      return { ...selectedAvailableField, isInPlan: true }
    } else if (m.mediaPlanVersionFieldId !== 0) {
      return m
    }
  }).filter(m => m)
  let updateMediaPlanVersion = { ...currentMediaPlan, mediaPlanVersionFields: newMediaPlanVersionFields }
  if (flightGroupIndex >= 0) {
    const newChosenFlights = currentMediaPlan.parseData.flightGroups[flightGroupIndex]?.chosenFlightFields.map(cf =>
      cf.clientMediaPlanFieldId === 0 ? { ...cf, clientMediaPlanFieldId: selectedAvailableField.clientMediaPlanFieldId } : cf
    )
    let newData = {}
    if (selectedAvailableField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT) {
      const newFlights = currentMediaPlan.parseData.flightGroups[flightGroupIndex].flights.map(flight => {
        const result = getWeeksBetweenByWeekDay(flight.flightStartDate, flight.flightEndDate)
        const newSubFlights = subFlightWidthCalculation(result.map((date) => ({
          subFlightStartDate: date.flightStartDate.format(dateFormat),
          subFlightEndDate: date.flightEndDate.format(dateFormat),
          clientMediaPlanFieldId: selectedAvailableField.clientMediaPlanFieldId
        })))
        return { ...flight, subFlights: [...(flight.subFlights || []), ...newSubFlights] }
      })

      newData = {
        ...currentMediaPlan.parseData,
        flightGroups: currentMediaPlan.parseData.flightGroups.map((fg, index) =>
          index === flightGroupIndex ? ({ ...fg, chosenFlightFields: newChosenFlights, flights: newFlights }) : fg
        )
      }
    } else {
      newData = {
        ...currentMediaPlan.parseData,
        flightGroups: currentMediaPlan.parseData.flightGroups.map((fg, index) =>
          index === flightGroupIndex ? ({ ...fg, chosenFlightFields: newChosenFlights }) : fg
        )
      }
    }

    updateMediaPlanVersion = { ...updateMediaPlanVersion, parseData: newData }
  }

  dispatch(updateMediaPlanStore(updateMediaPlanVersion))
}

const getNullValue = (field: IMediaPlanMetaField) => {
  const typeId = field.clientMediaPlanField.mediaPlanField.fieldDataTypeId
  let value
  if (typeId === 20 || typeId === 80) {
    value = ''
  } else {
    value = null
  }
  return value
}

export const removePlanField = (mediaPlanField: IMediaPlanMetaField, flightGroupIndex?: number) => (dispatch, getState: () => RootState) => {
  const currentMediaPlan = getState().mediaPlans.currentMediaPlanVersion
  const newMediaPlanVersionFields = currentMediaPlan.mediaPlanVersionFields.map((m) =>
    mediaPlanField.clientMediaPlanFieldId === m.clientMediaPlanFieldId
      ? { ...mediaPlanField, isInPlan: false }
      : m
  )
  const column = formatLowerCaseFirstLetter(mediaPlanField.clientMediaPlanField.mediaPlanField.columnName)
  let newData = { ...currentMediaPlan.parseData }
  let updateMediaPlanVersion: IMediaPlanVersion = { ...currentMediaPlan, mediaPlanVersionFields: newMediaPlanVersionFields }
  let shouldRecalculate = false
  if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.PLAN) {
    newData = { ...currentMediaPlan.parseData, [column]: getNullValue(mediaPlanField) }
  } else if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP) {
    newData = { ...newData, flightGroups: newData.flightGroups.map(flightGroup => ({ ...flightGroup, [column]: getNullValue(mediaPlanField) })) }
    const isFieldInSubtotalConfiguration = updateMediaPlanVersion.mediaPlan?.subtotalConfiguration?.subtotalConfigurationFields?.find((item) => item.clientMediaPlanFieldId === mediaPlanField.clientMediaPlanFieldId)
    if (isFieldInSubtotalConfiguration) {
      const newSubtotalsConfiguration = {
        ...updateMediaPlanVersion.mediaPlan.subtotalConfiguration,
        subtotalConfigurationFields: updateMediaPlanVersion.mediaPlan.subtotalConfiguration.subtotalConfigurationFields.filter((item) => item.clientMediaPlanFieldId !== mediaPlanField.clientMediaPlanFieldId)
      }
      updateMediaPlanVersion = {
        ...updateMediaPlanVersion,
        mediaPlan: {
          ...updateMediaPlanVersion.mediaPlan,
          subtotalConfiguration: newSubtotalsConfiguration
        }
      }
      shouldRecalculate = true
    }
  } else if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT && currentMediaPlan.parseData.calendarView !== 'table') {
    const chosenFlightFields = newData.flightGroups[flightGroupIndex]?.chosenFlightFields
      .filter(cf => cf.clientMediaPlanFieldId !== mediaPlanField.clientMediaPlanFieldId)
    newData = {
      ...newData,
      flightGroups: newData.flightGroups.map((flightGroup, index) =>
        index === flightGroupIndex ? { ...flightGroup, chosenFlightFields, flights: flightGroup.flights.map(flight => ({ ...flight, [column]: getNullValue(mediaPlanField) })) } : flightGroup
      )
    }
  } else if (mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.SUB_FLIGHT) {
    const chosenFlightFields = newData.flightGroups[flightGroupIndex]?.chosenFlightFields
      .filter(cf => cf.clientMediaPlanFieldId !== mediaPlanField.clientMediaPlanFieldId)
    newData = {
      ...newData,
      flightGroups: newData.flightGroups.map((flightGroup, index) =>
        index === flightGroupIndex ? {
          ...flightGroup,
          chosenFlightFields,
          flights: flightGroup.flights.map(flight => ({
            ...flight,
            subFlights: flight.subFlights.filter(sf => sf.clientMediaPlanFieldId !== mediaPlanField.clientMediaPlanFieldId).map(subFlight => ({
              ...subFlight,
              [column]: getNullValue(mediaPlanField)
            }))
          }))
        }
          : flightGroup
      )
    }
  }
  updateMediaPlanVersion = { ...updateMediaPlanVersion, parseData: newData }
  dispatch(updateMediaPlanStore(updateMediaPlanVersion))
  if (shouldRecalculate) {
    dispatch(recalculateAndRestoreHash())
  }
}

export const sortFlightGroups = (sortItems: ISortItem[], hierarchies: IMSHierarchies, masteredListsData: IMasteredListsData, financeList?: IMediaPlanVersionFinanceListFields) => (dispatch, getState: () => RootState) => {
  const currentMediaPlan = getState().mediaPlans.currentMediaPlanVersion
  const flightGroupFields = currentMediaPlan.mediaPlanVersionFields.filter(x => x.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP)
  const orderedFlightGroups = [...currentMediaPlan.parseData.flightGroups].sort((a, b) => {
    for (const sortItem of sortItems) {
      const first = getSortableValue(a, flightGroupFields, hierarchies, masteredListsData, sortItem.column, financeList)
      const second = getSortableValue(b, flightGroupFields, hierarchies, masteredListsData, sortItem.column, financeList)
      const result = compare(first, second, sortItem.ascending)

      if (result) {
        return result
      }
    }
  }).map((flightGroup, index) => {
    return { ...flightGroup, sortOrder: (index + 1) * 10 }
  })
  dispatch(updateMediaPlanStore({
    ...currentMediaPlan,
    parseData: {
      ...currentMediaPlan.parseData,
      flightGroups: orderedFlightGroups
    }
  }))
}

export const disabledApprove = () => dispatch => dispatch({ type: actionTypes.DISABLED_APPROVAL, payload: true })

export const updatePlanStage = (mediaPlanVersionId: number, clientId: number, planningStageId: number, planningStageName: string) => async (dispatch) => {
  try {
    dispatch(disablePlanButtons())

    const validationResults: IValidationResult = await mediaPlanService.putJson(`mediaPlanVersions/${mediaPlanVersionId}/planningStage?clientId=${clientId}`, planningStageId)
    if (validationResults.fieldResults.length > 0) {
      message.error('Planning Stage not updated, complete all validation errors')
      dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: validationResults.fieldResults })
      return validationResults.fieldResults
    } else {
      message.success(`Planning Stage updated to ${[planningStageName]}`)
      dispatch({ type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE, payload: { planningStageId } })
    }
  } catch {
    message.error('An error has occurred while updating the Planning Stage.')
  } finally {
    dispatch(enablePlanButtons())
  }
}

export const getPlanVersionHistory = (mediaPlanId: number, clientId: number) => async dispatch => {
  const planList = await mediaPlanService.getMediaPlanVersionHistory(mediaPlanId, clientId)
  dispatch({ type: actionTypes.SET_MEDIA_PLAN_HISTORY, payload: planList })
}

export const cleanPlanErrors = () => dispatch => dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: [] })

export const updateCalendarPlanDate = (planStartDate: string, planEndDate: string, calendarView: CalendarView, startDayOfWeek: string) =>
  (dispatch, getState: () => RootState) => {
    const planVersion = getState().mediaPlans.currentMediaPlanVersion
    const mediaPlanVersion = {
      ...planVersion,
      parseData: {
        ...planVersion.parseData,
        planStartDate,
        planEndDate,
        calendarView,
        startDayOfWeek
      }
    }
    dispatch(updateMediaPlanStore(mediaPlanVersion))
  }

export const addFlightTableView = (flight: IFlightDate, flightGroupIndex: number) => dispatch => {
  dispatch({ type: actionTypes.ADD_FLIGHT_TABLE_VIEW, payload: flight, flightGroupIndex })
  dispatch({ type: actionTypes.WEEK_MODE_ENABLED, payload: false })
}

export const removeFlightTableView = (flight: IFlightDate, flightGroupIndex: number) => dispatch => {
  dispatch({ type: actionTypes.REMOVE_FLIGHT_TABLE_VIEW, payload: flight, flightGroupIndex })
  dispatch({ type: actionTypes.VALIDATE_OVERLAP_FLIGHTS, flightGroupIndex })
  dispatch(runCalculationOnBlur(CalculationMessage.RemoveFlightTableView))
}

export const duplicateFlightTableView = (flight: IFlightDate, flightGroupIndex: number) => dispatch => {
  dispatch({ type: actionTypes.DUPLICATE_FLIGHT_TABLE_VIEW, payload: flight, flightGroupIndex })
  dispatch(runCalculationOnBlur(CalculationMessage.DuplicateFlightTableView))
}

export const validateOverlapFlights = (flightGroupIndex: number) => dispatch =>
  dispatch({ type: actionTypes.VALIDATE_OVERLAP_FLIGHTS, flightGroupIndex })

export const validateFlightDate = (flightGroupIndex: number, flightIndex: number) => dispatch =>
  dispatch({ type: actionTypes.VALIDATE_FLIGHT_DATE, flightGroupIndex, flightIndex })

export const cleanInvalidFlights = () => dispatch =>
  dispatch({ type: actionTypes.CLEAN_INVALID_FLIGHTS })

export const cleanFlightGroupWarnings = () => dispatch =>
  dispatch({ type: actionTypes.CLEAN_FLIGHT_GROUP_WARNINGS })

export const cleanFlightGroupWidths = () => dispatch => {
  dispatch({ type: actionTypes.CLEAN_FLIGHT_GROUP_WIDTHS })
}

// Media Plan
export const updateMediaPlan = (selectedPlan: IMediaPlans, clientId: number) => async () => {
  await mediaPlanService.putJson(`mediaPlans/${selectedPlan.mediaPlanId}?clientId=${clientId}`, { planYear: selectedPlan.planYear })
}

const createUniqueString = async (clientId: number, mediaPlanVersionFieldId: number, uniqueStringValue: string, dispatch: any, getState: any, mediaPlanFieldId: number) => {
  const newUniqueStringId: number = await mediaPlanService.postJson(`mediaPlanField/${mediaPlanFieldId}/uniqueStringValue?clientId=${clientId}`, uniqueStringValue)

  dispatch({
    type: actionTypes.SAVE_NEW_UNIQUE_STRING,
    payload: {
      id: mediaPlanVersionFieldId,
      value: newUniqueStringId,
      label: uniqueStringValue
    }
  })

  updateMediaPlanVersionFields(newUniqueStringId, newUniqueStringId, uniqueStringValue, getState, mediaPlanVersionFieldId, dispatch, mediaPlanFieldId)

  return newUniqueStringId
}

export const saveNewUniqueString = (newUniqueString: INewUniqueString) => async (dispatch, getState) => {
  const {
    id,
    flightGroupIndex,
    flightIndex,
    subFlightIndex,
    startDate,
    endDate
  } = newUniqueString.updateDataValuesFnParams

  const newUniqueStringId = await createUniqueString(newUniqueString.clientId, id, newUniqueString.uniqueStringValue, dispatch, getState, newUniqueString.mediaPlanFieldId)

  newUniqueString.updateDataValuesFn(
    newUniqueStringId.toString(),
    id,
    flightGroupIndex,
    flightIndex,
    subFlightIndex,
    startDate,
    endDate
  )
}

export const resetCurrentUniqueString = (updateDataValuesFn: (p: string) => void, updateDataValuesFnParam: string) => (dispatch) => {
  dispatch({
    type: actionTypes.SAVE_NEW_UNIQUE_STRING,
    payload: {
      id: null,
      value: null,
      label: null
    }
  })
  updateDataValuesFn(updateDataValuesFnParam)
}

export const pasteData = (clientId: number, data: string, mediaPlanField: IMediaPlanMetaField, flightGroupIndex: number, hierarchies: IMSHierarchies, masteredListsData: IMasteredListsData, financeList: IMediaPlanVersionFinanceListFields, flightIndex?: number) => async (dispatch, getState: () => RootState) => {
  // disabled calculations
  dispatch({ type: actionTypes.TOGGLE_CALCULATIONS, payload: false })

  // get rows for each breakline
  const rows = data.split('\n')

  // get cell value for each row
  const fieldCount = rows[0].split(/\t+/).length

  const { parseData, mediaPlanVersionFields } = { ...getState().mediaPlans.currentMediaPlanVersion }
  const calendarView: CalendarView = parseData.calendarView

  let columnNames: IPasteObject[] = []
  // paste was made at flight group level


  if (flightIndex === undefined) {
    const sortedFlightGroupFields = getSortedFlightGroupFields(mediaPlanVersionFields)
    const fieldIndex = sortedFlightGroupFields.findIndex(field => field.clientMediaPlanField.mediaPlanField.columnName === mediaPlanField.clientMediaPlanField.mediaPlanField.columnName)

    let count = 0
    // get camel case column names at flight group level
    const [currentCount, flightGroupColumnNames] = getFlightGroupNames(sortedFlightGroupFields, fieldIndex, fieldCount)
    columnNames = [...flightGroupColumnNames]
    count = currentCount
    let flightIndexPosition = 0
    // we have pasted values including flights

    if (columnNames.length < fieldCount) {
      const sortedFlightFields = getSortedFlightFields(mediaPlanVersionFields)
      flightIndexPosition = count + 1
      // get camel case column names at flight level
      const flightColumnNames = getFlightColumNames(sortedFlightFields, count, fieldCount)
      columnNames = [...columnNames, ...flightColumnNames]
    }
    rows.forEach(async (row, rowIndex) => {
      const cells = row.split(/\t+/)
      const currentFlightGroupIndex = rowIndex + flightGroupIndex
      const currentFlightIndex = parseData.flightGroups[currentFlightGroupIndex]?.flights?.findIndex(f => f.merge)
      let flight = currentFlightIndex ? { ...parseData.flightGroups[currentFlightGroupIndex].flights[currentFlightIndex] } : { merge: true }
      if ((!parseData.flightGroups[currentFlightGroupIndex] || parseData.flightGroups[currentFlightGroupIndex].length === 0) && cells[0].replace(/\r/, '') !== '') {
        dispatch(addFlightGroup(sortedFlightGroupFields))
      }
      await Promise.all(
        cells.map(async (cell, index) => {
          if (cell !== '' && columnNames[index] && !columnNames[index].isCalculatedOrAggregated) {
            const cellValue = cell.replace(/\r/, '')
            const parsedValue = await parseDataType(cellValue, columnNames[index].dataType.fieldDataTypeId, columnNames[index].clientFieldValues, masteredListsData, hierarchies,
              async () => await createUniqueString(clientId, columnNames[index].mediaPlanVersionFieldId, cellValue, dispatch, getState, columnNames[index].mediaPlanFieldId), financeList, columnNames[index].columnName)
            const newPropertyValue = { [columnNames[index].columnName]: parsedValue }

            if (index === 0 || flightIndexPosition === 0 || index < flightIndexPosition - 1) {
              dispatch(updatePlanFlightGroupValue(newPropertyValue, currentFlightGroupIndex))
            } else if (calendarView === 'table') {
              flight = {
                ...flight,
                ...newPropertyValue
              }
            }
          }
        }))

      if (calendarView === 'table' && flightIndexPosition !== 0) {
        dispatch({
          type: actionTypes.PASTE_FLIGHT_TABLE_VALUE,
          payload: flight,
          flightGroupIndex: currentFlightGroupIndex,
          flightIndex: currentFlightIndex || 0
        })
      }
    })
  } else if (calendarView === 'table') {
    const mergeFlights = getMergeFlights(parseData.flightGroups, flightGroupIndex, flightIndex)
    const sortedFlightFields = getSortedFlightFields(mediaPlanVersionFields)
    const fieldIndex = sortedFlightFields.findIndex(field => field.clientMediaPlanField.mediaPlanField.columnName === mediaPlanField.clientMediaPlanField.mediaPlanField.columnName)
    const flightColumnNames = getFlightColumnNames(sortedFlightFields, fieldCount, fieldIndex)
    rows.forEach(async (row, rowIndex) => {
      const cells = row.split(/\t+/)
      const flightData = mergeFlights[rowIndex]
      if (!flightData) {
        // more copied rows that available flights to paste in
        return
      }
      let flight = flightData.flight

      await Promise.all(
        cells.map(async (cell, index) => {
          if (cell !== '' && flightColumnNames[index] && !flightColumnNames[index].isCalculatedOrAggregated) {
            const cellValue = cell.replace(/\r/, '')
            const parsedValue = await parseDataType(cellValue, flightColumnNames[index].dataType.fieldDataTypeId, flightColumnNames[index].clientFieldValues, masteredListsData, hierarchies,
              async () => await createUniqueString(clientId, flightColumnNames[index].mediaPlanVersionFieldId, cellValue, dispatch, getState, flightColumnNames[index].mediaPlanFieldId), financeList, flightColumnNames[index].columnName)
            const newPropertyValue = { [flightColumnNames[index].columnName]: parsedValue }
            flight = {
              ...flight,
              ...newPropertyValue
            }
          }
        }))
      dispatch({ type: actionTypes.PASTE_FLIGHT_TABLE_VALUE, payload: flight, flightGroupIndex: flightData.flightGroupIndex, flightIndex: mergeFlights[rowIndex].index })
    })
  }
  // enable calculations
  dispatch({ type: actionTypes.TOGGLE_CALCULATIONS, payload: true })
}


export const getGoalSeekData = (goalSeekData: IGoalSeekData, currentClientId: number) => async (dispatch, getState: () => RootState) => {
  const goalSeekWizard = getState().mediaPlans.goalSeek.goalSeekModalData
  let goal = goalSeekWizard.goal

  if (goalSeekWizard.goalField.clientMediaPlanField.mediaPlanField.fieldDataTypeId === FieldDataType.CURRENCY) {
    goal = goalSeekWizard.goal * 100
  }

  let currentMediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }
  const mediaPlanVersionWithValidFlights = sanitiseMediaPlanVersionFlightsRevised(currentMediaPlanVersion)

  const result = await mediaPlanService.postJson(
    `mediaPlanVersions/goalseek/noSave?clientId=${currentClientId}`,
    {
      ...goalSeekData,
      goal,
      mediaPlanVersionJson: mediaPlanVersionWithValidFlights
    }
  )
  currentMediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }

  dispatch({ type: actionTypes.SET_PLAN_SUBTOTALS, payload: result.subtotals })
  if (currentMediaPlanVersion && currentMediaPlanVersion.parseData) {
    const parseData = parseCalculationResult(result, currentMediaPlanVersion)
    dispatch(updateMediaPlanStore({ ...currentMediaPlanVersion, parseData }))
    return result
  }
}


export const updateGoalSeekModalState = (mediaPlanField: IMediaPlanTemplateFields, value: number, instanceId: number, updatingGoalInput?: boolean) => (dispatch, getState: () => RootState) => {
  const goalSeekWizard = getState().mediaPlans.goalSeek.goalSeekModalData

  if (goalSeekWizard.wizardProgress === WizardProgress.SetGoalCell) {
    dispatch({
      type: actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE,
      payload: {
        ...goalSeekWizard,
        goalField: mediaPlanField,
        goalInstanceId: instanceId,
        wizardProgress: 1
      }
    })
  } else if (goalSeekWizard.wizardProgress > 0 && updatingGoalInput) {
    dispatch({
      type: actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE,
      payload: {
        ...goalSeekWizard,
        goal: value,
        wizardProgress: 2
      }
    })
  } else if (goalSeekWizard.wizardProgress === WizardProgress.SetTargetCell) {
    dispatch({
      type: actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE,
      payload: {
        ...goalSeekWizard,
        variableField: mediaPlanField,
        variableInstanceId: instanceId,
        initialVariableValue: value,
        wizardProgress: 3
      }
    })
  }
}

export const toggleGoalSeekModalVisible = () => (dispatch, getState: () => RootState) => {
  const modalVisible = getState().mediaPlans.goalSeek.goalSeekModalData.modalVisible
  batch(() => {
    dispatch({ type: actionTypes.TOGGLE_CALCULATIONS, payload: modalVisible })
    dispatch({ type: actionTypes.TOGGLE_GOAL_SEEK_MODAL_VISIBLE })
  })
}

export const resetGoalSeekModalState = () => dispatch =>
  dispatch({
    type: actionTypes.UPDATE_GOAL_SEEK_MODAL_STATE,
    payload: initialState.goalSeek.goalSeekModalData
  })

// Initialise / Clean Up
export const initialiseMediaPlanContainer = async (done, dispatch, clientId, props) => {
  const { mediaPlanVersionId } = props
  await batch(async () => {
    try {
      dispatch(cleanUpView())
      dispatch(setSelectedCalculatedField({} as any))
      dispatch({ type: actionTypes.WEEK_MODE_ENABLED, payload: true })
      await dispatch(setMediaPlanVersionById(mediaPlanVersionId, clientId, true))
      dispatch(runCalculationOnBlur())
      done()
    } catch {
      done('Media Plan failed to load. Please try refreshing. If that fails, contact administrator.')
    }
  })
}

export const cleanUpView = () => dispatch => {
  batch(() => {
    dispatch(enablePlanButtons())
    dispatch(cleanPlanErrors())
    dispatch(cleanFlightGroupWarnings())
    dispatch(cleanInvalidFlights())
    dispatch(cleanFlightGroupWidths())
    dispatch(updateMediaPlanStore(initialState.currentMediaPlanVersion))
    dispatch(clearCurrentMediaPlanTemplate())
    dispatch(expandAllFlightGroups())
    dispatch(expandAllFlightGroupRows())
    dispatch(setSelectedFlights([]))
    dispatch(setSelectedDates([]))
    dispatch(setSubtotalConfiguration(null, false))
  })
}
export const updateMediaPlanVersionFields = (newUniqueStringId: number, clientMediaPlanFieldId: number, uniqueStringValue: string, getState: any, id: number, dispatch: any, mediaPlanFieldId: number) => {
  const newField: ClientFieldValue = {
    clientFieldValueId: newUniqueStringId,
    isDisabled: false,
    valueDisplayName: uniqueStringValue,
    sortOrder: 0,
    mediaPlanFieldId
  }

  const currentMediaPlan = getState().mediaPlans.currentMediaPlanVersion as IMediaPlanVersion
  const newMediaPlanVersionFields = currentMediaPlan.mediaPlanVersionFields.map((m) => {
    if (id === m.mediaPlanVersionFieldId) {
      return {
        ...m,
        clientMediaPlanField: {
          ...m.clientMediaPlanField,
          clientFieldValues: [
            ...m.clientMediaPlanField.clientFieldValues,
            newField
          ]
        }
      }
    } else {
      return m
    }
  }).filter(m => m)

  dispatch({
    type: actionTypes.UPDATE_MEDIA_PLAN_VERSION_FIELDS,
    payload: newMediaPlanVersionFields
  })
}

export const getRedistributions = (mediaPlanVersionId: number, clientId: number) => async dispatch => {
  const redistributions = await mediaPlanService.get(`mediaPlans/${mediaPlanVersionId}/redistributions?clientId=${clientId}`)

  dispatch({
    type: actionTypes.GET_REDISTRIBUTIONS,
    payload: redistributions
  })
}

export const setCurrentRedistribution = (currentRedistribution: Redistribution, financeList = {}, hierarchies = {}, masteredListsData = {}) => async (dispatch, getState: () => RootState) => {
  let newRedistribution

  if (currentRedistribution) {
    const { parseData, mediaPlanVersionFields } = { ...getState().mediaPlans.currentMediaPlanVersion }

    const mediaPlanField = mediaPlanVersionFields.find((field) => field.clientMediaPlanFieldId === currentRedistribution.redistributeByClientMediaPlanFieldId)
    const columnName = formatLowerCaseFirstLetter(mediaPlanField?.clientMediaPlanField.mediaPlanField.columnName)

    newRedistribution = {
      ...currentRedistribution,
      redistributeByValues: currentRedistribution.redistributeByValues?.map((item) => {
        const valueId = isNumeral(item.value) ? Number(item.value) : item.value

        const fieldValues = getFieldValuesByLevelId(mediaPlanField, parseData, masteredListsData, hierarchies, financeList)
        const value = fieldValues.find((field) => {
          const fieldValueId = isNumeral(field.valueId) ? Number(field.valueId) : field.valueId

          return fieldValueId === valueId
        })?.value

        return {
          ...item,
          value: value?.[columnName] || value,
          valueId
        }
      })
    }
  } else {
    newRedistribution = currentRedistribution
  }

  dispatch({
    type: actionTypes.SET_CURRENT_REDISTRIBUTION,
    payload: newRedistribution
  })
}

export const deleteRedistribution = (mediaPlanVersionId: number, redistributionId: number, clientId: number) => async (dispatch, getState: () => RootState) => {
  const payload = await mediaPlanService.deleteJson(`mediaPlans/${mediaPlanVersionId}/redistributions/${redistributionId}?clientId=${clientId}`, {})
  const currentRedistribution = { ...getState().mediaPlans.currentRedistribution }

  if (currentRedistribution.redistributionId === payload) {
    dispatch(setCurrentRedistribution(null))
  }

  dispatch({
    type: actionTypes.DELETE_REDISTRIBUTION,
    payload
  })
}

export const createRedistribution = (mediaPlanVersionId: number, data: RedistributionPostPut, clientId: number) => async dispatch => {
  try {
    const result = await mediaPlanService.postJson(`mediaPlans/${mediaPlanVersionId}/redistributions?clientId=${clientId}`, data)
    dispatch(setCurrentRedistribution(result))
    return result
  } catch (error) {
    const errors = error.httpResponseBody && Object.values(error.httpResponseBody.errors)
    const errorMessage = errors ? errors.join('\r\n') : 'An error has occurred while creating redistribution.'
    message.error(errorMessage)
  }
}

export const updateRedistribution = (mediaPlanVersionId: number, redistributionId: number, data: RedistributionPostPut, clientId: number) => async dispatch => {
  try {
    const payload = await mediaPlanService.putJson(`mediaPlans/${mediaPlanVersionId}/redistributions/${redistributionId}?clientId=${clientId}`, data)

    dispatch({
      type: actionTypes.UPDATE_REDISTRIBUTION,
      payload
    })
    dispatch(setCurrentRedistribution(payload))
    return payload
  } catch (error) {
    const errors = error.httpResponseBody && Object.values(error.httpResponseBody.errors)
    const errorMessage = errors ? errors.join('\r\n') : 'An error has occurred while updating redistribution.'
    message.error(errorMessage)
  }
}

export const applyRedistribution = (data: IApplyRedistribution) => async (dispatch, getState) => {
  try {
    message.loading('Running redistribution')
    const mediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }
    const result = await mediaPlanService.postJson('mediaPlanVersions/redistributeFlightField/noSave', data)
    message.destroy()

    if (result.errors?.length > 0) {
      const errors = result.errors.join('\r\n')
      message.error({ content: errors })
    } else {
      message.success('Redistribution set successfully')
      dispatch(setRedistributeModalVisible(false))
      dispatch(setCurrentRedistribution(null))
      if (mediaPlanVersion && mediaPlanVersion.parseData) {
        const parseData = parseCalculationResult(result, mediaPlanVersion)
        dispatch({ type: actionTypes.SET_PLAN_ERRORS, payload: result.fieldResults })
        dispatch({ type: actionTypes.SET_MEDIA_PLAN_VERSION_BY_ID, payload: { ...mediaPlanVersion, parseData } })
        dispatch(runCalculationOnBlur())
      }
    }
    return result
  } catch (error) {
    message.error('There was an internal error')
  }
}

export const setSelectedRedistributeByField = ({ flightGroupIndex, flightIndex, ...selectedField }: { flightGroupIndex: number; flightIndex: number } & Omit<ISelectedRedistributeByField, 'flightGroup'>) => (dispatch, getState: () => RootState) => {
  const flightGroup = getState().mediaPlans.currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]

  dispatch({
    type: actionTypes.SET_SELECTED_REDISTRIBUTE_BY_FIELD,
    payload: { ...selectedField, flightGroup, flightGroupIndex, flightIndex }
  })
}

export const removeSelectedRedistributeByField = (id: number) => dispatch => {
  dispatch({ type: actionTypes.REMOVE_SELECTED_REDISTRIBUTE_BY_FIELD, payload: id })
}

export const clearSelectedRedistributeByFields = () => dispatch => {
  dispatch({ type: actionTypes.CLEAR_SELECTED_REDISTRIBUTE_BY_FIELDS })
}

export const setRedistributeModalVisible = (modalVisible) => (dispatch) => {
  dispatch({ type: actionTypes.SET_REDISTRIBUTE_MODAL_VISIBLE, payload: modalVisible })

  if (!modalVisible) {
    dispatch(clearSelectedRedistributeByFields())
  }
}

export const expandFlightGroupRow = (payload) => dispatch => {
  dispatch({ type: actionTypes.EXPAND_FLIGHT_GROUP_ROW, payload })
}

export const replaceCurrentFlightGroupSelection = (newSelection: Partial<IFlightGroup>) => dispatch => {
  dispatch({ type: actionTypes.REPLACE_FLIGHT_GROUPS_SELECTION, payload: newSelection })
}

export const clearFlightGroupSelection = () => dispatch => {
  dispatch({ type: actionTypes.CLEAR_FLIGHT_GROUP_SELECTION })
}

export const replaceFlightGroupSelection = (newSelection: Partial<IFlightGroup>, flightGroupId: number) => dispatch => {
  dispatch({ type: actionTypes.REPLACE_SINGLE_FLIGHT_GROUP_SELECTION, payload: newSelection, flightGroupId })
}

const recalculateAndRestoreHash = () => async (dispatch, getState: () => RootState) => {
  const calculatorHash = getState().mediaPlans.currentMediaPlanVersion?.parseData?.calculatorHash
  const mediaPlanVersion = { ...getState().mediaPlans.currentMediaPlanVersion }
  let calculationResult

  dispatch({ type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE, payload: { calculatorHash: null } })
  try {
    calculationResult = await dispatch(runCalculationOnBlur())
  } finally {
    const parseData = parseCalculationResult(calculationResult, mediaPlanVersion)
    const sanitizedData = {
      ...mediaPlanVersion,
      parseData
    }

    dispatch({ type: actionTypes.SET_PLAN_FIELD_LEVEL_VALUE, payload: { calculatorHash } })
    dispatch(updateMediaPlanStore(sanitizedData))
  }
}

export const setSubtotalConfiguration = (newConfiguration: SubtotalConfiguration, resetCalculatorHash: boolean) => (dispatch) => {
  dispatch({ type: actionTypes.SET_SUBTOTAL_CONFIGURATIONS, payload: newConfiguration })

  if (resetCalculatorHash) {
    dispatch(recalculateAndRestoreHash())
  }
}

export const removeMediaPlanFromCoreM = (mediaPlanId: number, clientId: number) => async dispatch => {
  try {
    await mediaPlanService.putJson(`mediaPlans/${mediaPlanId}/removeFromCoreM?clientId=${clientId}`, {})
  } catch (err) {
    return err.httpResponseBody
  }
  dispatch({
    type: actionTypes.REMOVE_MEDIA_PLAN_FROM_COREM
  })
}
