import React, { CSSProperties, FunctionComponent, memo, useCallback } from 'react'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { CopyOutlined, MinusCircleOutlined, ArrowsAltOutlined, ShrinkOutlined, EllipsisOutlined } from '@ant-design/icons'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IPoint } from 'Helpers/selectionHelper'
import { useFocus } from 'Hooks/useFocus'
import { useFlightGroupSelection } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelection'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'

interface IProps {
  calendarView: CalendarView
  collapse: boolean
  flightGroupArrayLength: number
  flightGroupId: number
  flightGroupIndex: number
  canInsert: boolean
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  onCollapse: (flightGroupId: number) => void
  onDuplicateFlightGroup: (flightGroupIndex: number) => void
  onDeleteFlightGroup: (flightGroupIndex: number) => void
  onSelect: (event: React.MouseEvent<HTMLElement, MouseEvent>, point: IPoint, flightGroupSelection: Partial<IFlightGroup>, isSelected: boolean) => void
  onReset: (flightGroupSelection: Partial<IFlightGroup>) => void
  onCopy: (flightGroupSelection: Partial<IFlightGroup>) => void
  onInsert: (params: { shift: 0 | 1; withFlights: boolean; flightGroupIndex: number; flightGroupSelection: Partial<IFlightGroup> }) => void
  isSubtotalGroup: boolean
}

export const ButtonFlightGroupComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    calendarView,
    collapse,
    flightGroupArrayLength,
    flightGroupId,
    flightGroupIndex,
    onCollapse,
    onDuplicateFlightGroup,
    onDeleteFlightGroup,
    getStickyStyle,
    onSelect,
    onReset,
    onCopy,
    onInsert,
    canInsert,
    isSubtotalGroup
  } = props
  const [ref, focus] = useFocus()
  const { isSelected, flightGroupSelection } = useFlightGroupSelection({ mediaPlanFlightGroupId: flightGroupId }) ?? {
    isSelected: false,
    flightGroupSelection: null
  }

  const handleSelection = useCallback((event) => {
    onSelect(event, { flightGroupIndex, flightGroupFieldIndex: -1 }, flightGroupSelection, isSelected)
  }, [onSelect, flightGroupIndex, flightGroupSelection, isSelected])

  const handleCollapse = useCallback(() => {
    onCollapse(flightGroupId)
  }, [onCollapse, flightGroupId])

  const handleDuplicate = useCallback(() => {
    onDuplicateFlightGroup(flightGroupIndex)
  }, [onDuplicateFlightGroup, flightGroupIndex])

  const handleDelete = useCallback(() => {
    onDeleteFlightGroup(flightGroupIndex)
  }, [onDeleteFlightGroup, flightGroupIndex])

  const handleCopy = useCallback(() => {
    onCopy(flightGroupSelection)
  }, [onCopy, flightGroupSelection])

  const handleInsertAboveWithFlights = useCallback(() => {
    onInsert({ shift: 0, withFlights: true, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertAboveWithoutFlights = useCallback(() => {
    onInsert({ shift: 0, withFlights: false, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertBelowWithFlights = useCallback(() => {
    onInsert({ shift: 1, withFlights: true, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleInsertBelowWithoutFlights = useCallback(() => {
    onInsert({ shift: 1, withFlights: false, flightGroupIndex, flightGroupSelection })
  }, [onInsert, flightGroupIndex, flightGroupSelection])

  const handleReset = useCallback(() => {
    onReset(flightGroupSelection)
  }, [onReset, flightGroupSelection])

  return (
    <td style={getStickyStyle(StickyColumnType.toolbar)}>
      <div className={`flightGroup__toolbar ${collapse ? '-collapsed' : ''}`}>
        {
          calendarView !== 'table' && (
            <Tooltip placement='topRight' title={collapse ? 'Expand flight group field' : 'Collapse flight group field'}>
              <Button
                data-testid='btn-collapse'
                icon={collapse ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
                onClick={handleCollapse}
                size='large'
                tabIndex={-1}
                type='link'
              />
            </Tooltip>
          )
        }
        {
          !collapse && !isSubtotalGroup &&
          (
            <>
              <Tooltip placement='right' title='Clone flight group'>
                <Button
                  icon={<CopyOutlined />}
                  size='large'
                  type='link'
                  tabIndex={-1}
                  onClick={handleDuplicate}
                  data-testid='btn-duplicated'
                />
              </Tooltip>
              {flightGroupArrayLength > 1 && (
                <Tooltip placement='right' title='Remove flight group'>
                  <Button
                    icon={<MinusCircleOutlined />}
                    size='large'
                    type='link'
                    tabIndex={-1}
                    onClick={handleDelete}
                    data-testid='btn-remove-group'
                  />
                </Tooltip>
              )}
              <Dropdown
                overlay={
                  <Menu className="flightGroup__context-menu" onClick={focus}>
                    <Menu.Item disabled={!isSelected} onClick={handleCopy}>
                      Copy
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item disabled={!canInsert} onClick={handleInsertAboveWithFlights}>
                      Insert Above
                    </Menu.Item>
                    <Menu.Item disabled={!canInsert} onClick={handleInsertAboveWithoutFlights}>
                      Insert Above Without Flights
                    </Menu.Item>
                    <Menu.Item disabled={!canInsert} onClick={handleInsertBelowWithFlights}>
                      Insert Below
                    </Menu.Item>
                    <Menu.Item disabled={!canInsert} onClick={handleInsertBelowWithoutFlights}>
                      Insert Below Without Flights
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item disabled={!isSelected} onClick={handleReset}>
                      Clear Data
                    </Menu.Item>
                  </Menu>
                }
                trigger={['contextMenu']}
              >
                <Tooltip placement='right' title='Select flight group'>
                  <Button
                    icon={<EllipsisOutlined />}
                    onClick={handleSelection}
                    onContextMenu={handleSelection}
                    size='large'
                    tabIndex={-1}
                    type='link'
                    data-testid='btn-select-group'
                    ref={ref}
                  />
                </Tooltip>
              </Dropdown>
            </>
          )}
      </div>
    </td>
  )
}

export default memo(ButtonFlightGroupComponent)
