import React, { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button } from 'antd'
import { QuestionOutlined } from '@ant-design/icons'
import ModalComponent from './ModalComponent'

interface IInfoModalComponentProps {
  text: string
}

const InfoModalComponent: React.FC<IInfoModalComponentProps> = ({ text }) => {
  const [visible, setVisible] = useState(false)
  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => setVisible(false), [])

  return (
    <div className='tooltip'>
      <Button
        className='ms-button'
        data-testid='tooltip'
        type='primary'
        onClick={showModal}
      >
        <QuestionOutlined />
      </Button>
      <ModalComponent
        onCancel={hideModal}
        visible={visible}
        width={900}
        footer={null}
        modalBody={<ReactMarkdown linkTarget="_blank" source={text} />}
      />
    </div>
  )
}

export default InfoModalComponent
