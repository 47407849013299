import React, { FunctionComponent } from 'react'
import { Select } from 'antd'
import { Client } from 'Apis/generated/clientsApi'

const { Option } = Select

export interface IProps {
  clients: Client[]
  currentClient: Client
  clientSelect: (clientID: number) => void
}

export const ClientSelectionComponent: FunctionComponent<IProps> = ({
  clients,
  currentClient,
  clientSelect
}) => {
  if (!clients?.length) {
    return null
  } else {
    return (
      <div className='client-selection-inner'>
        <label>Client:</label>
        <Select
          className='ms-select'
          data-testid='client-select'
          defaultValue={clients[0].displayName}
          onChange={(clientID) => clientSelect(Number(clientID))}
          value={currentClient.displayName}
          optionFilterProp='children'
          showSearch={true}
        >
          {clients.map((client) => <Option key={client.id} value={client.id}>{client.displayName}</Option>)}
        </Select>
      </div>
    )
  }
}

export default ClientSelectionComponent
