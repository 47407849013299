import React from 'react'

import createEditFrontSheetPlanSelectionText from 'Components/tooltipContent/FrontSheets/Create Edit Front Sheet Plan selection'
import createEditFrontSheetViewEditorText from 'Components/tooltipContent/FrontSheets/Create Edit Front Sheet View editor'
import templateEditText from 'Components/tooltipContent/Edit Template'
import InfoModalComponent from './InfoModalComponent'

interface IProps {
  tooltip: string
}

const TooltipButton: React.FC<IProps> = ({ tooltip }) => {
  switch (tooltip) {
    case 'templateEdit': {
      return <InfoModalComponent text={templateEditText} />
    }
    case 'createEditFrontSheetPlanSelection': {
      return <InfoModalComponent text={createEditFrontSheetPlanSelectionText} />
    }
    case 'createEditFrontSheetViewEditor': {
      return <InfoModalComponent text={createEditFrontSheetViewEditorText} />
    }

    default:
      return null
  }
}

export default TooltipButton
