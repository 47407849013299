import { formatLowerCaseFirstLetter, IMSHierarchies } from 'mindshare.layout'
import { uniqBy } from 'lodash'

import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFlightsWithData } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { FinanceAudience, FinanceBookingCategory } from 'Apis/generated/financeDataApi'
import { getValueOrLookupText } from 'Helpers/flightHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

const VALID_PERCENTAGE_AMOUNT = 1


export const checkUniqueField = (items: any, value: string, field: string) => {
  return !items?.find(item => item?.[field] === value?.trim())
}

/**
 * Returns an array of evenly divided numbers
 * Example:
 * splitPercentageEvenly(2) => [50, 50]
 * splitPercentageEvenly(3) => [33, 33, 34]
 */
export const splitPercentageEvenly = (splitBy, num = 100, scale = 1 / 100) =>
  [...Array(splitBy)].map((_item, idx) =>
    idx - splitBy + 1
      ? Math.floor(num / splitBy) * scale
      : (num - idx * Math.floor(num / splitBy)) * scale
  )

export const checkPercentageAmount = (values) => {
  const percentageAmount = values.reduce((acc, value) => acc + value.percentage, 0)
  return percentageAmount === VALID_PERCENTAGE_AMOUNT
}


export const getFieldValuesByLevelId = (mediaPlanField, parseData, masteredListsData, hierarchies, financeList) => {
  const columnName = formatLowerCaseFirstLetter(mediaPlanField?.clientMediaPlanField.mediaPlanField.columnName)
  const fieldValues = []

  if (mediaPlanField?.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT_GROUP) {
    parseData.flightGroups.forEach((flightGroup) => {
      const flightGroupValue = flightGroup[columnName]
      const value = getValueOrLookupText(flightGroupValue, mediaPlanField, hierarchies, masteredListsData, financeList)
      // eslint-disable-next-line functional/immutable-data
      fieldValues.push({
        id: mediaPlanField.clientMediaPlanFieldId,
        value: value?.[columnName] || value,
        valueId: flightGroup?.[columnName]
      })
    }
    )
  } else if (mediaPlanField?.clientMediaPlanField.mediaPlanField.fieldLevelId === FieldLevelType.FLIGHT) {
    parseData.flightGroups.forEach((flightGroup) =>
      flightGroup.flights.forEach((flight) => {
        const flightValue = flight[columnName]
        const value = getValueOrLookupText(flightValue, mediaPlanField, hierarchies, masteredListsData, financeList)

        if (flightValue !== undefined) {
        // eslint-disable-next-line functional/immutable-data
          fieldValues.push({
            id: mediaPlanField.clientMediaPlanFieldId,
            value: value?.[columnName] || value,
            valueId: value?.[columnName] || value
          })
        }
      })
    )
  }

  return fieldValues
}

export const getUniqueFieldValues = (fieldValues) => uniqBy(fieldValues, 'value')

export const getCorrectFieldValue = (
  fieldLevel: IFlightGroup | any[],
  columnName: string,
  mediaPlanField: IMediaPlanTemplateFields,
  hierarchies: IMSHierarchies,
  masteredListsData: IMasteredListsData,
  financeList: FinanceAudience[] | FinanceBookingCategory[]
) => {
  const valueOrLookupText = getValueOrLookupText(
    fieldLevel?.[columnName],
    mediaPlanField,
    hierarchies,
    masteredListsData,
    financeList
  )

  return valueOrLookupText?.[columnName] || valueOrLookupText
}


export const getAvailableFlightFields = (
  currentRedistribution,
  mediaPlanVersionFields,
  flightGroup,
  flight,
  currentFieldColumnName,
  hierarchies,
  masteredListsData,
  fieldLevelId,
  financeList
) => {
  const fieldRedistributeBy = mediaPlanVersionFields.find(
    field =>
      field.clientMediaPlanFieldId === currentRedistribution?.redistributeByClientMediaPlanFieldId
  )
  const chosenRedistributeByValues = currentRedistribution?.redistributeByValues.map(
    item => item.value
  )

  const fieldToRedistributeColumnName = formatLowerCaseFirstLetter(
    currentRedistribution?.redistributeClientMediaPlanField.mediaPlanField
      .columnName
  )
  const fieldToRedistributeByColumnName = formatLowerCaseFirstLetter(
    currentRedistribution?.redistributeByClientMediaPlanField.mediaPlanField
      .columnName
  )

  let isFieldValidToRedistribute = false
  if (
    currentRedistribution?.redistributeByClientMediaPlanField.mediaPlanField
      .fieldLevelId === FieldLevelType.FLIGHT_GROUP
  ) {
    const flightGroupFieldValue = getCorrectFieldValue(
      flightGroup,
      fieldToRedistributeByColumnName,
      fieldRedistributeBy,
      hierarchies,
      masteredListsData,
      financeList
    )
    const isFlightLevelField = fieldLevelId === FieldLevelType.FLIGHT
    const isValidValue = chosenRedistributeByValues.includes(flightGroupFieldValue) || chosenRedistributeByValues.includes(flightGroup?.[fieldToRedistributeByColumnName])
    isFieldValidToRedistribute = (
      isValidValue &&
      currentFieldColumnName === fieldToRedistributeColumnName && isFlightLevelField
    )
  } else if (
    currentRedistribution?.redistributeByClientMediaPlanField.mediaPlanField
      .fieldLevelId === FieldLevelType.FLIGHT && flight
  ) {
    const flightFieldValue = getCorrectFieldValue(
      flight,
      fieldToRedistributeByColumnName,
      fieldRedistributeBy,
      hierarchies,
      masteredListsData,
      financeList
    )
    const isValidValue = chosenRedistributeByValues.includes(flightFieldValue) || chosenRedistributeByValues.includes(flight?.[fieldToRedistributeByColumnName])
    isFieldValidToRedistribute = (
      isValidValue &&
      currentFieldColumnName === fieldToRedistributeColumnName
    )
  }

  return isFieldValidToRedistribute
}

export const formApiRedistributionFlightsData = (chosenFields, columnName) =>
  chosenFields.map(field => {
    const flightsWithData = getFlightsWithData(field.flightGroup.flights)
    const newFlight = flightsWithData.find(
      flight => flight.mediaPlanFlightId === field.instanceId
    )

    return {
      flightParentGroup: { ...field.flightGroup, flights: flightsWithData },
      flight: {
        ...newFlight,
        [columnName]: newFlight[columnName] || 0
      }
    }
  })
