import React, { FunctionComponent } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Tooltip } from 'antd'
import { MSTable } from 'mindshare.layout'

import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { FieldTag } from 'Apis/generated/fieldTagsApi'
import TagListComponent from 'Components/TagListComponent'
import CheckboxComponent from 'Components/CheckboxComponent'
import FieldTypeComponent from '../FieldTypeComponent'
import FieldAliasComponent from '../FieldAliasComponent'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { compare } from 'Helpers/sortHelper'

export interface ITermTableProps {
  selectedMediaPlanTerms: IClientMediaPlanField[]
  handleDropdownListValue: (value: string, mediaPlanField: IClientMediaPlanField) => void
  handleItemDisabledToggle: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField) => void
  handleItemSort: (clientMediaPlanField: IClientMediaPlanField, direction: 'asc' | 'dsc') => void
  handleItemOrder: (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField, direction: 'up' | 'down') => void
  handleMandatoryToggle: (selectedRow: IClientMediaPlanField) => void
  handleDisableToggle: (selectedRow: IClientMediaPlanField) => void
  handleAliasAddition: (selectedRow: IClientMediaPlanField, newValue: string) => void
  handleIsApportioned: (selectedRow: IClientMediaPlanField) => void
  handleIsApportionmentField: (selectedRow: IClientMediaPlanField) => void
}

const DataTermsTable: FunctionComponent<ITermTableProps> = ({
  selectedMediaPlanTerms,
  handleMandatoryToggle,
  handleAliasAddition,
  handleDisableToggle,
  handleDropdownListValue,
  handleItemDisabledToggle,
  handleItemSort,
  handleItemOrder,
  handleIsApportioned,
  handleIsApportionmentField
}) => {
  const columns: Array<ColumnProps<IClientMediaPlanField>> = [
    {
      key: 'MediaPlanTermName',
      title: 'Name',
      sorter: (a, b) =>
        compare(a.mediaPlanField.fieldLabel, b.mediaPlanField.fieldLabel),
      dataIndex: ['mediaPlanField', 'fieldLabel'],
      render: (fieldLabel: string, clientMediaPlanField) => (<Tooltip placement='topLeft' title={clientMediaPlanField.mediaPlanField.fieldDescription}>{fieldLabel}</Tooltip>)
    },
    {
      key: 'MediaPlanTermAlias',
      title: 'Alias (Optional)',
      dataIndex: 'fieldLabel',
      render: (fieldLabel: string, clientMediaPlanField) => (
        <FieldAliasComponent
          placeholder='Enter alias'
          value={fieldLabel === clientMediaPlanField.mediaPlanField.fieldLabel ? '' : fieldLabel}
          approved={clientMediaPlanField.approvedOn !== undefined && clientMediaPlanField.approvedOn !== null && clientMediaPlanField.savedFieldLabel === clientMediaPlanField.fieldLabel}
          onAliasChange={(newValue) => handleAliasAddition(clientMediaPlanField, newValue)}
        />
      )
    },
    {
      key: 'MediaPlanTermMandatory',
      title: 'Mandatory',
      dataIndex: 'isMandatory',
      render: (isMandatory: boolean, mediaPlanTerm) => (
        <CheckboxComponent
          checked={isMandatory}
          dataTestId={'mandatory'}
          Toggle={() => handleMandatoryToggle(mediaPlanTerm)}
        />
      )
    },
    {
      key: 'MediaPlanTermIsApportioned',
      title: 'Apportionment',
      dataIndex: 'isApportionmentField',
      render: (isApportionmentField: boolean, mediaPlanTerm) => {
        const dataTypeId = mediaPlanTerm.mediaPlanField.fieldDataTypeId
        const fieldLevelId = mediaPlanTerm.mediaPlanField.fieldLevelId
        const allowedDataTypes = [FieldDataType.INTEGER, FieldDataType.DECIMAL, FieldDataType.CURRENCY]
        const enabled = allowedDataTypes.some(c => c === dataTypeId) && fieldLevelId === FieldLevelType.FLIGHT
        return (
          <CheckboxComponent
            checked={isApportionmentField}
            dataTestId='apportionmentField'
            disabled={!enabled}
            Toggle={() => handleIsApportionmentField(mediaPlanTerm)}
          />
        )
      }
    },
    {
      key: 'MediaPlanTermIsApportionmentField',
      title: 'Apportioned',
      dataIndex: 'isApportioned',
      render: (isApportioned: boolean, mediaPlanTerm) => (
        <CheckboxComponent
          checked={isApportioned}
          dataTestId={'apportioned'}
          disabled={!mediaPlanTerm.mediaPlanField.isApportionable}
          Toggle={() => handleIsApportioned(mediaPlanTerm)}
        />
      )
    },
    {
      key: 'MediaPlanFieldType',
      title: 'Disabled',
      dataIndex: 'isDisabled',
      render: (isDisabled: boolean, mediaPlanTerm) => (
        <CheckboxComponent
          dataTestId={'disabled'}
          checked={isDisabled}
          Toggle={() => handleDisableToggle(mediaPlanTerm)}
        />
      )
    },
    {
      key: 'MediaPlanTermTags',
      title: 'Tags',
      dataIndex: ['mediaPlanField', 'fieldTags'],
      render: (tags: FieldTag[]) => (<TagListComponent tags={tags} />)
    },
    {
      key: 'MediaPlanFieldLevel',
      title: 'Level',
      dataIndex: ['mediaPlanField', 'fieldLevel', 'fieldLevelName'],
      sorter: (a, b) => compare(a.mediaPlanField.fieldLevel.fieldLevelName,
        b.mediaPlanField.fieldLevel.fieldLevelName)
    },
    {
      key: 'MediaPlanTermDropDownValues',
      title: 'Field Type',
      render: mediaPlanTerm => (
        <FieldTypeComponent
          mediaplanTerm={mediaPlanTerm}
          clientFieldValues={mediaPlanTerm.clientFieldValues}
          handleAddDropdown={handleDropdownListValue}
          handleItemDisabledToggle={handleItemDisabledToggle}
          handleItemSort={handleItemSort}
          handleItemOrder={handleItemOrder}
        />
      )
    },
    {
      key: 'MediaPlanDataType',
      title: 'Data Type',
      dataIndex: ['mediaPlanField', 'fieldDataType', 'dataTypeName']
    }
  ]

  return (
    <div className='list-table'>
      <MSTable
        rowKey='clientMediaPlanFieldId'
        columns={columns}
        dataSource={selectedMediaPlanTerms}
        dynamicPagination={{ minRows: 6 }}
      />
    </div>
  )
}

export default DataTermsTable
