import * as actionTypes from 'Constants/actionTypes'
import * as mediaPlanService from '../../services/mediaPlanService'
import { createMediaPlanField, IMediaPlanFieldExtended, IFieldDataTypeExtended, IMediaPlanField } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'

interface ISetMediaPlanFieldDataAction {
  type: actionTypes.SET_MEDIA_PLAN_FIELD_DATA
  payload: IMediaPlanFieldExtended[]
}


export type MediaPlanFieldActions =
  | ISetMediaPlanFieldDataAction


export interface IDispatchMediaPlanField extends Omit<IMediaPlanFieldExtended, 'fieldDataType'> {
  fieldDataType?: string | IFieldDataTypeExtended
}

export const setMediaPlanFieldData = (data: IDispatchMediaPlanField[]): any => ({
  type: actionTypes.SET_MEDIA_PLAN_FIELD_DATA,
  payload: data
})
export const saveMediaPlanFieldData = (data: IMediaPlanField[], clientId: number, validate = false): any => async () => {
  const selectedMediaPlanFields = data.filter(el => el.isSelected)

  if (selectedMediaPlanFields.length > 0) {
    const tObj = selectedMediaPlanFields.map(el => ({
      clientMediaPlanFieldId: 0,
      mediaPlanFieldLevelId: el.mediaPlanFieldLevelId,
      isMandatory: el.isMandatory,
      sortOrder: el.sortOrder,
      isApportioned: el.isDefaultApportioned,
      isApportionmentField: el.isDefaultApportionmentField,
      clientFieldValues: []
    }))

    await mediaPlanService.postJson(`clientMediaPlanFields?clientId=${clientId}&validate=${validate}`, tObj)
  }
}


export const getMediaPlanFields = (clientId: number) => async dispatch => {
  const payload = await mediaPlanService.get(`mediaPlanFields?includeSelected=false&clientId=${clientId}`)
  const mediaPlanFields: IMediaPlanField[] = payload.map((f) =>
    createMediaPlanField({
      mediaPlanFieldLevelId: f.mediaPlanFieldLevelId,
      fieldLabel: f.fieldLabel,
      fieldDataTypeId: f.fieldDataTypeId,
      columnName: f.columnName,
      fieldLevelId: f.fieldLevelId,
      fieldDescription: f.fieldDescription,
      isMandatory: f.isMandatory,
      sortOrder: f.sortOrder,
      fieldTags: f.fieldTags,
      isApportionable: f.isApportionable,
      isDefaultApportioned: f.isDefaultApportioned,
      isDefaultApportionmentField: f.isDefaultApportionmentField
    })
  )

  dispatch({ type: actionTypes.SET_MEDIA_PLAN_FIELD_DATA, payload: mediaPlanFields })
}
